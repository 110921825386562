import { isEmpty } from "lodash";
import { ApiServices } from "./apiServices";
import {
  Constants,
  MasterDocumentTypeConstants,
  permissionConstants,
} from "./keyConstants";
import constant from "services/constant";
import * as url from "services/url_helper";
import * as permissiomUrl from "pages/Permission/utils/urlHelper";
import { Box, Stack } from "@mui/material";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import * as masterDocUrl from "pages/DynamicDocument/utils/urlHelper";
import slugify from "slugify";

export const dynamicDateOperation = ({
  baseDate = moment(),
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  operation = "add",
} = {}) => {
  let currentDate = moment(baseDate);

  if (operation === "add") {
    currentDate = currentDate
      .add(months, "months")
      .add(days, "days")
      .add(hours, "hours")
      .add(minutes, "minutes");
  } else if (operation === "subtract") {
    currentDate = currentDate
      .subtract(months, "months")
      .subtract(days, "days")
      .subtract(hours, "hours")
      .subtract(minutes, "minutes");
  } else {
    throw new Error('Invalid operation. Use "add" or "subtract".');
  }

  return currentDate.format("YYYY-MM-DD HH:mm:ss");
};

// Local storage related functions
export const setItem = (key, value, encrypt = true) => {
  const secretKey = "wJ5@9F2!hYpZ6q#Xv$Lm%8dNvP3&bC";

  // For Value
  const valueString =
    typeof value === "object" ? JSON.stringify(value) : value?.toString();

  if (encrypt) {
    // Encrypt the value if the flag is true
    const encrypted = CryptoJS.AES.encrypt(valueString, secretKey).toString();
    localStorage.setItem(key, encrypted);
  } else {
    // Store value directly without encryption
    localStorage.setItem(key, valueString);
  }
};

const decryptItem = (encrypted, isEncrypted = true) => {
  const secretKey = "wJ5@9F2!hYpZ6q#Xv$Lm%8dNvP3&bC";

  if (!encrypted) {
    return null; // No data found for the given key
  }

  try {
    if (isEncrypted) {
      // Decrypt the encrypted string
      const bytes = CryptoJS.AES.decrypt(encrypted, secretKey);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

      // Try to parse as JSON (since the original value might be an object)
      try {
        return JSON.parse(decryptedString);
      } catch (error) {
        // If parsing fails, return as a plain string
        return decryptedString;
      }
    } else {
      // If the value is not encrypted, try to parse it directly as JSON
      try {
        return JSON.parse(encrypted);
      } catch (error) {
        // Return the value as a plain string if it's not JSON
        return encrypted;
      }
    }
  } catch (error) {
    console.error("Error decrypting data: ", error.message);
    return null; // Return null if decryption fails
  }
};

export const getItem = (key, isEncrypted = true) => {
  const encrypted = localStorage.getItem(key);
  const decryptedString = decryptItem(encrypted);
  return isEncrypted ? decryptedString : encrypted;
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

// Mui Chip Color For Organogram Chart
export const getRandomColor = (designation) => {
  const colorMap = {
    "Managing Director": "info",
    Director: "primary",
  };

  const colors = ["default", "secondary", "error", "success", "warning"];

  return (
    colorMap[designation] || colors[Math.floor(Math.random() * colors.length)]
  );
};

export const getUser = () => {
  let user = localStorage.getItem(Constants.KEY_USER_DATA);
  const decryptedUser = decryptItem(user);
  if (!decryptedUser) {
    return null;
  } else {
    return decryptedUser;
  }
};

// to filter roleData based on designation name
export const getDepartmentData = (roleData, designation) => {
  return roleData?.filter(
    (role) =>
      role?.designation === designation &&
      typeof role?.userName === "object" &&
      role?.userName !== null
  );
};

// function to check if value is not null and undefined
export const isNotNullOrUndefined = (value) =>
  value !== null && value !== undefined;

// function to capital first letter of string
export const capitalizeFirstLetter = (string) =>
  string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();

export const capitalizeFirstLetterInString = (string, isCondition = false) => {
  if ((isCondition = false)) {
    return string
      ?.split(" ")
      .map((word) => word?.charAt(0)?.toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return string
      ?.split(" ")
      .map((word, index) => {
        if (word.toLowerCase() === "of" || word.toLowerCase() === "for") {
          return word.toLowerCase(); // Keep "of" and "for" lowercase
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of other words
      })
      .join(" ");
  }
};

export const formatTimestampToUTC = (timestamp) => {
  const date = new Date(timestamp);
  return !isNaN(date) ? date?.toUTCString() : ""; // Return UTC string or empty string if invalid
};

export const trimUserName = (userName) => {
  if (userName) {
    const nameEndIndex = userName?.indexOf("(");
    if (nameEndIndex !== -1) {
      return userName?.slice(0, nameEndIndex).trim(); // Trim username
    }
  }
  return userName;
};

export const isUserLoggedIn = () => {
  let token = getItem(Constants.KEY_USER_TOKEN);
  let userData = getItem(Constants.KEY_USER_DATA);
  if (isEmpty(token) && isEmpty(userData)) {
    return false;
  } else {
    return true;
  }
};

export const getSecureToken = (key) => {
  const data = localStorage.getItem(key);
  const decryptedToken = decryptItem(data);

  if (!decryptedToken) {
    return null;
  } else {
    return decryptedToken;
  }
};
export const extractContentFromHTML = (html) => {
  var span = document.createElement("span");
  span.innerHTML = html;
  return span.textContent || span.innerText;
};

export const logout = () => {
  localStorage.clear();
  window.location.replace("/");
};

export const verifyDomain = async () => {
  try {
    const { hostname } = window.location;
    setItem(Constants.KEY_HOST_NAME, hostname);

    const subDomain = hostname.split(".")[0];
    if (subDomain === "client") {
      return { validDomain: true };
    }

    const reqObj = {
      domainLink: hostname,
    };

    const uri = url.CHECK_VERIFIED_DOMAIN;
    const response = await ApiServices.callServicePostWithBodyData(uri, reqObj);

    const validDomain = response?.meta?.success || false;
    const data = response?.result || null;

    sessionStorage.setItem(`${subDomain}`, JSON.stringify(validDomain));

    return { validDomain, data };
  } catch (error) {
    console.error("CHECK_VERIFIED_DOMAIN error", error);
    return { validDomain: false, data: null };
  }
};

export const modifyQuestions = (questionsData, standardName) => {
  let data = {
    standard: standardName,
    sections: {},
  };

  questionsData?.forEach((question) => {
    const {
      section,
      questionContent,
      questionConstant,
      choices,
      parentQuestion,
      parentChoice,
      sectionConstant,
    } = question?.question;

    if (!data.sections[section]) {
      data.sections[section] = {
        constants: sectionConstant,
        docType: question?.docType,
        questions: {},
      };
    }

    if (question?.questionType === "mcq Question") {
      data.sections[section].questions[questionContent] = {
        constants: questionConstant,
        answer:
          question?.question?.answer === true
            ? true
            : question?.question?.answer === false
            ? false
            : null,
        type: "radio",
        parentQuestionId: question?._id,
        options: choices,
        suggestion: question?.question?.suggestions ?? "",
        questionFor: question?.docType,
        linkSection: question?.question?.linkSection ?? null,
        procedure: question?.question?.procedure ?? null,
      };
    } else if (question.questionType === "mcmc Question") {
      if (
        questionContent ===
        // "Provide Non-applicability of the quality management system. (if any requirement in clause 6, 7, or 8 of ISO 13485:2016 is not applicable due to the activities undertaken by the organization or the nature of the medical device, no need to include such requirement in the quality management system. for any clause that is determined to be non-applicable, provide the justification. Identified non applicable clause 6.4.2, 7.5.2, 7.5.3, 7.5.4, 7.5.5, 7.5.6, 7.5.7, 7.5.10, 8.3.4)"
        "Provide Non-applicability of the quality management system. (if any requirement in clause 6, 7, or 8 of ISO 13485:2016 is not applicable due to the activities undertaken by the organization or the nature of the medical device, no need to include such requirement in the quality management system."
      ) {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : [],
          type: "checkbox",
          parentQuestionId: question._id,
          options: choices,
          suggestion: question.question.suggestions ?? "",
          tableData: question.tableData != null ? question.tableData : [],
          questionFor: question.docType,
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
        };
      } else {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : [],
          type: "checkbox",
          parentQuestionId: question._id,
          options: choices,
          suggestion: question.question.suggestions ?? "",
          questionFor: question.docType,
          tableData:
            question.tableData === null || question.tableData === undefined
              ? getData(question.question.choices, question.question.answer)
              : question.tableData,
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
        };
      }
    } else if (question.questionType === "short Question") {
      if (parentQuestion && parentChoice) {
        // Ensure parentQuestion exists in questions object
        if (!data.sections[section].questions[parentQuestion]) {
          data.sections[section].questions[parentQuestion] = {};
        }

        // Ensure subquestion property exists for the parentQuestion
        if (!data.sections[section].questions[parentQuestion].subquestion) {
          data.sections[section].questions[parentQuestion].subquestion = {};
        }

        data.sections[section].questions[parentQuestion].subquestion[
          questionContent
        ] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "text",
          subQuestionId: question._id,
          suggestion: question.question.suggestions ?? "",
          questionFor: question.docType,
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
        };
      } else {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "text",
          parentQuestionId: question._id,
          suggestion: question.question.suggestions ?? "",
          procedure:
            question?.question?.procedure !== undefined
              ? question?.question?.procedure
              : null,
          questionFor: question.docType,
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
        };
      }
    } else if (question.questionType === "Dropdown Question") {
      if (parentQuestion && parentChoice) {
        // Ensure parentQuestion exists in questions object
        if (!data.sections[section].questions[parentQuestion]) {
          data.sections[section].questions[parentQuestion] = {};
        }

        // Ensure subquestion property exists for the parentQuestion
        if (!data.sections[section].questions[parentQuestion].subquestion) {
          data.sections[section].questions[parentQuestion].subquestion = {};
        }

        data.sections[section].questions[parentQuestion].subquestion[
          questionContent
        ] = {
          // constants: questionConstant,
          // answer: question.question.answer ? question?.question?.answer : "",
          // type: "text",
          // subQuestionId: question._id,
          // suggestion: question.question.suggestions ?? "",
          // questionFor: question.docType,
          // linkSection: question?.question?.linkSection ?? null,
          // procedure: question?.question?.procedure ?? null,

          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "dropdown",
          multiple: question?.question?.multiple,
          parentQuestionId: question._id,
          options: choices, // List of dropdown options
          suggestion: question.question.suggestions ?? "",
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
          questionFor: question.docType,
        };
      } else {
        data.sections[section].questions[questionContent] = {
          constants: questionConstant,
          answer: question.question.answer ? question?.question?.answer : "",
          type: "dropdown",
          multiple: question?.question?.multiple,
          parentQuestionId: question._id,
          options: choices, // List of dropdown options
          suggestion: question.question.suggestions ?? "",
          linkSection: question?.question?.linkSection ?? null,
          procedure: question?.question?.procedure ?? null,
          questionFor: question.docType,
        };
      }
    }
  });
  return data;
};

const getData = (data, keys) => {
  const dataMap = data?.reduce((map, item) => {
    map[item?.choice] = item?.defaultData;
    return map;
  }, {});

  // Extract the defaultData arrays using the keys array
  const arrays = keys?.map((key) => dataMap[key] || []);

  // Create the output array by combining corresponding elements from the extracted arrays
  const result = arrays?.[0]?.map((_, index) => {
    const obj = {};
    keys?.forEach((key, keyIndex) => {
      const labelKey = key.replace(/\s+/g, "").toLowerCase(); // Convert 'Title' to 'title', 'Prepared By' to 'preparedby'
      // Check if defaultData array exists before accessing its elements
      obj[labelKey] = arrays[keyIndex][index]
        ? arrays?.[keyIndex]?.[index].label
        : null;
    });
    return obj;
  });

  return result;
};

export const generateTableHTML = (data) => {
  const keys = Object.keys(data[0]);
  const headers = keys.map((key) => key.charAt(0).toUpperCase() + key.slice(1));
  let html = `<table style="border-collapse: collapse; width: 100%">`;

  html += "<tbody>";
  html += "<tr>";
  headers.forEach((header) => {
    html += `<td ><strong>${header}</strong></td>`;
  });
  html += "</tr>";

  // Add rows
  data.forEach((row) => {
    html += "<tr>";
    headers.forEach((header) => {
      const key = header.toLowerCase().replace(/ /g, "");
      html += `<td>${row[key]}</td>`;
    });
    html += "</tr>";
  });

  html += `</tbody></table>`;
  return html;
};
export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const dispatchFunction = (response) => {
  const message = response?.message || response?.result?.message;
  const messageType =
    response?.type || response?.result?.type || response?.response;
  if (isNotNullOrUndefined(message) && message !== "Fetched successfully") {
    dispatch(
      openSnackbar({
        open: true,
        message,
        variant: "alert",
        alert: {
          color:
            messageType === "success" || messageType === true
              ? "success"
              : "error",
        },
        close: false,
      })
    );
  }
};

export const formatMuiDate = (date, format = "DD-MM-YYYY") => {
  return dayjs(date).format(format);
};

export const showToast = (response, flag) => {
  const message = response?.meta?.message || response;
  const messageType = response?.meta?.success || flag || false;

  if (isNotNullOrUndefined(message) && message !== "Fetched successfully") {
    dispatch(
      openSnackbar({
        open: true,
        message,
        variant: "alert",
        alert: {
          color: messageType === true ? "success" : "error",
        },
        close: false,
      })
    );
  }
};

export const filterUserById = (userDataList) => {
  const userIds = userDataList
    ?.filter((user) => user?.userId || user?._id) // Filter out users with empty or null userId
    ?.map((user) => ({ userId: user?.userId || user?._id })); // Return objects with { userId: <userId value> }

  return userIds;
};

export const transformUserDesignationObject = (obj) => ({
  userId: obj?.userData?.userId || "",
  name: obj?.userData?.name || "",
  designation: obj?.designation || "",
});

export const sendNotification = async (reqObj, isReturn = false) => {
  const URL = url.SEND_NOTIFICATIONS;
  let result = await ApiServices.callServicePostWithBodyData(URL, reqObj);
  if (isReturn) {
    return result;
  }
};
export const sendSpecificNotification = async (reqObj) => {
  const URL = url.SEND_SPECIFIC_NOTIFICATIONS;
  await ApiServices.callServicePostWithBodyData(URL, reqObj);
};

export const getDocumentLifeCycle = async (reqObj) => {
  const URL = url.GET_DOCUMENT_LIFE_CYCLE;
  return await ApiServices.callServicePostWithBodyData(URL, reqObj);
};

// for getting first user id and name by passing user array
export const extractUserIdAndName = (users) => {
  if (users?.length === 0) return null;
  const user = users?.[0];

  if (!user?.userId) {
    return null; // Return null if userId is an empty string
  }

  return {
    userId: user?.userId,
    name: user?.userName || user?.name,
    designation: user?.designation,
    designationId: user?.designationId,
  };
};

// for getting all user id and name by passing user array
export const extractUserDetails = (users) => {
  if (!Array.isArray(users) || users.length === 0) {
    return [];
  }

  return users
    ?.filter((user) => user?.userId) // Filter out users with empty or undefined userId
    ?.map((user) => ({
      userId: user?.userId,
      name: user?.userName || user?.name,
      designation: user?.designation,
      designationId: user?.designationId,
    }));
};

// for getting first user id and name by passing object
export const extractUserIdAndNameByObject = (user) => {
  if (!user) return null;

  if (!user?.userId) {
    return null; // Return null if userId is an empty string
  }

  return {
    userId: user?.userId,
    name: user?.userName || user?.name,
    designation: user?.designation,
    designationId: user?.designationId,
  };
};
export const extractUserDataByDepartmentAndDesignation = (
  data,
  userIdOnly = false
) => {
  const userDataList = [];

  // Arrow function to recursively traverse the data
  const traverse = (obj) => {
    // Use optional chaining to safely access 'children' property
    const children = obj?.children;

    if (children && Array.isArray(children)) {
      children.forEach((child) => {
        const userData = child?.userData;

        if (userData && userData?.userId !== "") {
          userDataList?.push({
            userId: userData?.userId,
            name: userData?.name,
            // responsibilities: userData?.responsibilities,
            designation: child?.designation,
          });
        }

        // Recursively traverse the child objects if they have children
        traverse(child);
      });
    }
  };

  // Start the traversal from the root object
  traverse(data?.defaultDesignation?.[0]);

  const userIds = userDataList
    ?.filter((user) => user?.userId)
    .map((user) => user.userId);

  return userIdOnly ? userIds : userDataList;
};

export const getUserData = (userData) => {
  if (!userData) return null;
  let selectedRole = getItem(Constants.KEY_SELECTED_ROLE);

  return {
    userId: userData?._id || null,
    name: userData?.name || null,
    designation:
      selectedRole?.roleName || userData?.externalUser?.type || userData?.role,
    designationId: selectedRole?.roleId || null,
  };
};

// Get the last character of a string
export const getLastCharacter = (str) => {
  return str?.slice(-1) || null;
};

// get the string length by passing number
export const hasValidLength = (value, length = 0, comparison) => {
  const stringLength = String(value)?.length;
  if (comparison === ">") {
    return stringLength > length;
  } else if (comparison === "===") {
    return stringLength === length;
  }
  // If no comparison is provided, just check if length is greater than the default length
  return stringLength > length;
};

// Convert the entire string to lowercase
export const toLowercaseString = (str) => {
  return str ? str.toLowerCase() : null;
};

export const getUserDataByDefaultDesignation = (data, isUserId) => {
  const userDataList = [];

  // Arrow function to recursively traverse the data
  const traverse = (obj) => {
    // Check if the current object matches the titleToMatch
    if (obj.title === titleToMatch) {
      // Extract user data from children if they exist
      const children = obj?.children;

      if (children && Array.isArray(children)) {
        children?.forEach((child) => {
          const userData = child?.userData;

          if (userData) {
            userDataList.push({
              userId: userData?.userId,
              name: userData?.name,
              responsibilities: userData?.responsibilities,
            });
          }
          // Recursively traverse the child objects if they have children
          traverse(child);
        });
      }
    } else {
      // Recursively traverse the child objects even if the title doesn't match
      const children = obj?.children;
      if (children && Array.isArray(children)) {
        children.forEach((child) => traverse(child));
      }
    }
  };

  // Start the traversal from the root object
  traverse(data?.defaultDesignation?.[0]);

  const userIds = userDataList?.map((user) => ({
    userId: user?.userId,
  }));

  return isUserId ? userIds : userDataList;
};

export function formatKeyToCameCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const toCamelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+|_|\-|\s)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, "");
};

export function camelCaseToString(camelCaseStr) {
  return (
    camelCaseStr
      // Replace uppercase letters with space + lowercase equivalent
      .replace(/([A-Z])/g, " $1")
      // Capitalize the first letter of the result
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

// function for converting into camcelCaseToNormal
export const camelCaseToNormal = (str) =>
  str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, (c) => c.toUpperCase());

export const generateUUID = () => uuidv4();

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};
export function formatKey(key) {
  return (
    key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .charAt(0)
      .toUpperCase() + key.slice(1).replace(/[A-Z]/g, " $&")
  );
}

export const getTitle = (title) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 1, pb: 0, fontSize: 20 }}
    >
      <h5>{title}</h5>
    </Stack>
  );
};

export const getConFigData = (questions) => {
  const result = {};
  const traverse = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === "object") {
          traverse(obj[key]);
        } else {
          if (key === "constants" && obj) {
            result[obj.constants] = obj?.answer ?? "";
            if (obj?.tableData && obj?.tableData?.length > 0) {
              result[obj.constants] = obj?.tableData ?? [];
            }
          }
        }
      }
    }
  };
  traverse(questions);
  return result;
};

export const getObjectByIndexOrFindIndex = (array, input) => {
  if (typeof input === "number") {
    return array[input];
  } else if (typeof input === "function") {
    const index = array.findIndex(input);
    return index !== -1 ? array[index] : null;
  }
  return null;
};

export function formatObjecKey(str) {
  return str.replace(/([A-Z])/g, " $1").toUpperCase();
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date != "Invalid Date"
    ? date.toLocaleDateString(undefined, options)
    : "N/A";
};

// for generating date and time based on date
export const renderTimestamp = (
  item,
  singleLine = false,
  type = "dateTime"
) => {
  const date = item ? new Date(item) : null;
  const formattedDate = date ? date.toLocaleDateString() : "-";
  const formattedTime = date ? date.toLocaleTimeString() : "-";

  const renderContent = () => {
    switch (type) {
      case "date":
        return formattedDate;
      case "time":
        return formattedTime;
      default: // "dateTime"
        return singleLine ? (
          `${formattedDate} ${formattedTime}`
        ) : (
          <>
            <div className="timestamp-date">{formattedDate}</div>
            <div className="timestamp-time">{formattedTime}</div>
          </>
        );
    }
  };

  return renderContent();
};

export const formatDateWithYear = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
  };
  return date != "Invalid Date"
    ? date.toLocaleDateString(undefined, options)
    : "N/A";
};

export const dateFormatter = (selectedDate) => {
  const date = new Date(selectedDate);

  // Get day of the week (short form)
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[date.getUTCDay()];

  // Get month (short form)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getUTCMonth()];

  // Get date components
  const day = String(date.getUTCDate()).padStart(2, "0"); // Add leading zero if needed
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, "0"); // Add leading zero if needed
  const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // Add leading zero if needed
  const seconds = String(date.getUTCSeconds()).padStart(2, "0"); // Add leading zero if needed

  // Format the timezone offset
  const timezoneOffset = date.getTimezoneOffset();
  const timezoneOffsetHours = Math.abs(Math.floor(timezoneOffset / 60));
  const timezoneOffsetMinutes = Math.abs(timezoneOffset % 60);
  const timezoneOffsetString = "+0530";
  // (timezoneOffset >= 0 ? "-" : "+") +
  // timezoneOffsetHours.toString().padStart(2, "0") +
  // timezoneOffsetMinutes.toString().padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezoneOffsetString} (India Standard Time)`;

  return formattedDate;
};

// function for change document number based on headers , documentNumber and revision number
export const changeDocumentNumber = (
  headers,
  documentNumber,
  revisionNumber
) => {
  for (let item of headers) {
    if (
      item.title === "Document No.:" ||
      item.title === "Document Number" ||
      item.title === "Document Number:"
    ) {
      item.value = documentNumber;
    }
    if (
      item.title === "Revision No.:" ||
      item.title === "Revision Number" ||
      item.title === "Rev. No."
    ) {
      item.value = revisionNumber;
      break;
    }
  }
  return headers;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// for downloading file
export const downloadFile = async (fileName) => {
  const proxyUrl = constant.PROXY_URL;

  try {
    const response = await fetch(
      `${proxyUrl}?pdfLocation=${encodeURIComponent(fileName)}`,
      { method: "GET" }
    );

    if (!response.ok) throw new Error("Network response was not ok");

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = `${fileName}`;
    document.body.appendChild(link);

    link.click();

    // Cleanup
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const checkAuthorization = (data) => {
  let details = data?.map((item) => item?.userId).flat();
  let userData = getItem(Constants.KEY_USER_DATA);
  return details?.includes(userData._id);
};

//function for get document title
export const getDocumentTitle = (title) => {
  return `${title} | ${capitalizeFirstLetter(
    window?.location?.hostname?.split(".")[0]
  )}`;
};

export const getAllUserIds = (data, excludeIds = []) => {
  return data
    ?.filter((item) => item?.userId && Object.keys(item.userName).length > 0)
    ?.filter((item) => !excludeIds.includes(item?.userId))
    ?.map((item) => ({ userId: item?.userId }));
};

export const getAllUserIdsForDepartment = (data) => {
  return data
    ?.filter((item) => item?.userId)
    ?.map((item) => ({ userId: item?.userId }));
};

export const getYearFromDate = (dateString) => {
  return moment(dateString).year();
};

export const validationDynamicTable = (rows, columns) => {
  const newErrors = {};
  rows?.forEach((row, rowIndex) => {
    columns?.forEach((col) => {
      const { value, displayValue, validation } = col;
      if (validation) {
        if (validation.required && !row[displayValue ?? value]) {
          if (!newErrors[rowIndex]) newErrors[rowIndex] = {};
          newErrors[rowIndex][displayValue ?? value] = validation?.msg;
        }
      }
    });
  });
  return newErrors;
};

export const validateColumns = (columns) => {
  const newErrors = {};

  // Iterate over the columns and validate each one
  columns?.forEach((col) => {
    const { value, validation, inputValue } = col;

    // Determine if the column should be hidden
    const isHidden =
      col?.hidden?.key && col?.hidden?.value && col?.hidden?.checkingField
        ? columns?.find(
            (item) => item?.[col?.hidden?.checkingField] === col?.hidden?.key
          )?.inputValue !== col.hidden.value
        : false;

    // Skip validation for hidden columns
    if (isHidden) {
      return;
    }

    // Validate required fields
    if (validation?.required && !inputValue) {
      newErrors[value] = validation?.msg || "This field is required.";
    }
    // Validate pattern if defined
    if (validation?.pattern && inputValue) {
      const patternInString = convertPattern(validation.pattern);
      const regex = new RegExp(patternInString);
      if (!regex.test(inputValue)) {
        newErrors[value] = validation?.patternMsg || "Invalid format.";
      }
    }
  });

  return newErrors;
};

const convertPattern = (patternString) => {
  // Check if the pattern includes delimiters (e.g., /pattern/flags)
  const regexParts = patternString?.match(/^\/(.*?)\/([gimsuy]*)$/);

  if (regexParts) {
    // If delimiters exist, return the pattern as a RegExp object
    return new RegExp(regexParts[1], regexParts[2]);
  } else {
    // If no delimiters, treat the entire string as the pattern
    return new RegExp(patternString);
  }
};

export const addSpaceBetweenCapitals = (str) =>
  str.replace(/([a-z])([A-Z])/g, "$1 $2");

export const downloadFormat = async (url, reqObj, onChangeDownloadState) => {
  // Show the toast indicating download has started
  showToast("File started to download", true);

  try {
    // Set the download state to true
    onChangeDownloadState(true);

    // Make an API call to fetch the file
    const result = await ApiServices.callServicePostWithBodyData(url, reqObj);
    // Show the result via toast
    showToast(result);

    if (result?.meta?.success) {
      const pdfLocation = result?.data;

      // Download the file from the PDF location
      await downloadFile(pdfLocation);
    }
  } catch (error) {
    console.error("Download error:", error);
    showToast("Error during download", false);
  } finally {
    // Reset the download state to false
    onChangeDownloadState(false);
  }
};
export const handleGetPermission = (moduleName, permissionType) => {
  // const storedObject = getItem(Constants?.PERMISSIONDATA);
  // if (storedObject?.length === 0) {
  //   return undefined;
  // }
  // const formatPermissions = (data) => {
  //   const formattedData = {};
  //   data?.forEach((item) => {
  //     formattedData[item?.moduleName] = item?.permissions;
  //   });

  //   return formattedData;
  // };
  // const formattedPermissions = formatPermissions(storedObject);
  // const searchModule = (obj, moduleName, permissionType) => {
  //   for (let key in obj) {
  //     if (key === moduleName) {
  //       return obj[key][permissionType];
  //     }

  //     if (typeof obj[key] === "object") {
  //       const found = searchModule(obj[key], moduleName, permissionType);
  //       if (found !== undefined) {
  //         return found;
  //       }
  //     }
  //   }
  //   return undefined;
  // };

  // return searchModule(formattedPermissions, moduleName, permissionType);
  return true;
};

// for getting nested value of object
export const getNestedValue = (obj, field) => {
  // If the field includes a dot, treat it as a nested field
  if (field?.includes(".")) {
    const keys = field?.split("."); // Split the field by dot notation
    return keys?.reduce((acc, key) => (acc ? acc[key] : undefined), obj); // Iterate through keys to access the nested value
  } else {
    // If no dot, simply access the field directly
    return obj[field];
  }
};

export const checkUserPermission = async (
  clientUserId,
  moduleName,
  permissionType
) => {
  try {
    const reqObj = {
      clientUserId: clientUserId,
      permissions: moduleName,
    };

    const result = await ApiServices.callServicePostWithBodyData(
      url.GET_PERMISSION_FOR_USER,
      reqObj
    );

    if (result?.success) {
      if (result?.role?.permissions?.[moduleName]?.[permissionType]) {
        return true;
      } else {
        showToast(
          `Please provide ${permissionType} permission for ${moduleName} to ${result?.role?.clientUserId?.name}`,
          false
        );
        return false;
      }
    } else {
      showToast(
        `Please provide ${permissionType} permission for ${moduleName} to ${result?.role?.clientUserId?.name}`
      );
      return false;
    }
  } catch (error) {
    console.error("GET_PERMISSION_FOR_USER", error);
  }
};

export const documentObj = {
  // Manuals
  organogram: "Organogram",
  qualityPolicy: "Quality Policy",
  qualityObjective: "Quality Objective",
  rolesResponsibilityAuthority: "Roles Responsibility & Authority",
  interactionOfProcess: "Interaction Of Process",
  listOfProcedure: "List Of Procedure",
  appointmentLetter: "Appointment Letter",
  qualityManual: "Quality Manual",
  // Procedures
  controlOfDocuments: "Control Of Document",
  controlOfRecords: "Control Of Records",
  managementReviewMeeting: "Management Review Meeting",
  internalQualityAudit: "Internal Quality Audit",
  feedback: "Feedback",
  competenceAwarenessAndTraining: "Competence Awareness And Training",
  customerComplaints: "Customer Complaints",
  internalCommunication: "Internal Communication",
  monitoringAndMeasuringOfProduct: "Monitoring And Measuring Of Product",
  correctiveActionPreventiveAction: "Corrective Action Preventive Action",
  processValidation: "Process Validation",
  purchase: "Purchase",
  installation: "Installation",
  workEnvironment: "Work Environment",
  communicationWithRegulatoryBodies: "Communication With Regulatory Bodies",
  monitoringAndMeasurementOfEquipment:
    "Monitoring And Measurement Of Equipment",
  servicing: "Customer Service",
  preventiveMaintenance: "Preventive Maintenance",
  controlOfNonConforming: "Control Of Non Confirming",
  production: "Control Of Production",
  customerRelatedProcessAndDistribution:
    "Customer Related Process And Distribution",
  advisoryNoticeAndRecall: "Advisory Notice And Recall",
  customerProperty: "Control Of Customer Property",
  analysisOfData: "Data Analysis",
  designAndDevelopment: "Design And Development",
  identificationAndTraceability: "Identification And Traceability",
  monitoringAndMeasurementOfProcess: "Monitoring And Measurement Of Process",
  preservationOfProduct: "Preservation Of Product",
  productRealization: "Product Realization",
  sterilizationValidation: "Sterilization Validation",
  postMarketSurveillance: "Post Market Surveillance",

  // Formats
  internalQualityAuditSchedule: "Internal quality audit schedule",
  listOfAuditors: "List of Auditors",
  internalAuditPlan: "Internal audit plan",
  internalQualityAuditReport: "Internal quality audit report",
  correctiveActionReport: "Corrective Action Report",
  competencyMatrix: "Competency Matrix",
  requisitionForManpower: "Requisition for Manpower",
  manPowerListWithSpecimenSignature: "Man-Power List with Specimen Signature",
  inductionTrainingRecord: "Induction Training Record",
  trainingEvaluationRecord: "Training Evaluation Record",
  trainingNeedAssessmentForm: "Training Need Assesment Form",
  trainingCalendar: "Training Calender",
  trainingAttendanceSheet: "Training Attendance Sheet",
  trainingEvaluationRecord: "Training Evaluation Record",
  checklistForInstallation: "Checklist for Installation",
  installationReport: "Installation Report",
  mrmPlan: "MRM-Plan",
  mrmCircular: "MRM Circular",
  minutesOfMeeting: "Minutes Of Meeting",
  supplierServiceProviderSelection: "Supplier/Service Provider Selection",
  purchaseOrder: "Purchase Order",
  subcontractorAgreement: "Subcontractor Agreement",
  rawMaterialStockRegister: "Raw material Stock register",
  incomingInspectionReport: "Incoming Inspection Report",
  supplierEvaluationForm: "Supplier Evaluation form",
  supplierAgreement: "Supplier Agreement",
  requisitionSlip: "Requisition Slip",
  issueSlip: "Issue Slip",
  approvedSupplierList: "Approved Supplier List",
  supplierReevaluationForm: "Supplier Re-evaluation Form",
  agreementWithLaboratory: "Agreement with laboratry",
  feedback: "Feedback",
  internalCommunication: "Internal Communication",
  agentData: "Agent Data",
  customerOrderForm: "Customer Order Form",
  salesInformations: "Sales informations",
  distributionRecords: "Distribution Records",
  orderReviewForm: "Order Review Form",
  rejectionRecords: "Rejection Records",
  returnedGoodsRecords: "Returned Goods Records",
  reworkRecords: "Rework Records",
  scrapRecords: "Scrap Records",
  concessionRecord: "Concession Record",
  cleaningRegisterRecord: "Cleaning register record",
  fumigationRecord: "Fumigation Record",
  personalHygieneRecord: "Personal hygiene record",
  apronAndSlipperCleaningRecord: "Apron and slipper cleaning record",
  tempAndHumidityMonitoringRecord: "Temp and humidity monitoring record",
  customerComplaint: "Customer Complaint",
  correctiveAction: "Corrective Action",
  preventiveAction: "Preventive Action",
  masterValidationPlan: "Master Validation Plan",
  validationProtocolAndReport: "Validation Protocol & Report",
  advisoryNotice: "Advisory notice",
  productRecallRegister: "Product Recall Register",
  returnedGoodsRecords: "Returned Goods Records",
  productionPlanning: "Production Planning",
  requisitionSlip: "Requisition Slip",
  batchManufacturingRecord: "Batch Manufacturing Record",
  semiFinishedStockRegister: "Semi-Finished Stock Register",
  masterListOfMachine: "Master List Of Machine",
  maintenanceReport: "Maintanance Report",
  preventiveMaintenancePlan: "Preventive maintenance plan",
  machineBreakdownReport: "Machine Break down report",
  masterListOfEquipment: "Master list of equipment",
  internalListOfEquipment: "Internal list of equipment",
  internalCalibrationReport: "Internal clibration report",
  incomingInspectionPlan: "Incoming Inspection Plan",
  incomingInspectionRecord: "Incoming Inspection Record",
  inProcessInspectionPlan: "In process Inspection Plan",
  inProcessInspectionRecord: "In process Inspection Record",
  finalInspectionPlan: "Final Inspection Plan",
  finalInspectionRecord: "Final Inspection Record",
  independentInProcessInspectionReport:
    "Independent In Process Inspection Report",
  customerServicingDetails: "Customer Servicing Details",
  customerServiceRecord: "Customer Service Record",
  customerServicingRecord: "customer-servicing-record",
  customerServiceFrom: "Customer Service from",
};

// for destructure data based on array of objects
export const destructureOrganogramData = (data) => {
  return Object.values(data)?.flatMap((users) => users) || [];
};

// for getting master doc data by defaultName
export const getDocumentLifeCycleForMasterDoc = async (
  documentName,
  moduleType,
  selectFields = "defaultName,moduleType,signatureUsers"
) => {
  try {
    let reqObj = {
      defaultName: documentName,
      selectFields: selectFields,
      ...(moduleType && { moduleType: moduleType }),
    };

    const queryString = new URLSearchParams(reqObj).toString();
    const uri = `${masterDocUrl.GET_DATA_BY_DEFAULTNAME}?${queryString}`;
    let result = await ApiServices.callServiceGet(uri);
    if (result?.meta?.success) {
      return result?.data;
    } else {
      showToast(result);
    }
  } catch (error) {
    console.error("CREATE_MASTER_TABLE ERROR", error);
  }
};

// for getting format data by documentName
export const getFormatDataById = async (
  documentName,
  selectFields = "_id,structuredData,structureType,masterDocId"
) => {
  try {
    const queryParams = {
      defaultName: documentName,
      selectFields: selectFields,
    };
    let result = await ApiServices.callServiceGet(
      buildUrl(url?.GET_FORMAT, queryParams)
    );
    if (result?.meta?.success) {
      return result?.data;
    } else {
      showToast(result);
    }
  } catch (error) {
    console.error(error);
  }
};

let userData = getItem(Constants.KEY_USER_DATA);

// for getting format data by documentName
export const getPermssionDataByName = async (
  documentName,
  documentType = MasterDocumentTypeConstants.RECORD
) => {
  try {
    // If `userData?.externalUser` exists, return an empty array
    if (userData?.externalUser) {
      return [];
    }
    const reqObj = {
      defaultName: Array.isArray(documentName) ? documentName : [documentName],
      documentType: documentType,
    };
    let result = await ApiServices.callServicePostWithBodyData(
      permissiomUrl?.GET_PERMISSSION_BY_NAME,
      reqObj
    );
    if (result?.meta?.success) {
      return result?.data;
    } else {
      showToast(result);
    }
  } catch (error) {
    console.error(error);
  }
};

const selectedRole = getItem(Constants.KEY_SELECTED_ROLE) || {};
export const checkPermission = (
  data,
  defaultName,
  permissionKey = permissionConstants.CREATE
) => {
  if (selectedRole?.departmentDefaultName === "Top Management") {
    return true;
  } else {
    const doc = data?.find((item) => item?.defaultName === defaultName);
    return doc ? doc?.permission[permissionKey] || false : false;
  }
};

export const getExternalFormatDataById = async (
  documentName,
  orgId,
  selectFields = "_id,structuredData,structureType,masterDocId"
) => {
  try {
    const queryParams = {
      defaultName: documentName,
      selectFields: selectFields,
      orgId: orgId,
    };
    let result = await ApiServices.callServiceGet(
      buildUrl(url?.GET_EXTERNAL_FORMAT, queryParams)
    );
    if (result?.meta?.success) {
      return result?.data;
    } else {
      showToast(result);
    }
  } catch (error) {
    console.error(error);
  }
};

// make build url for search query
export const buildUrl = (base, params) => {
  const queryString = new URLSearchParams(params).toString();
  return `${base}?${queryString}`;
};

// for convert to kebeb case
export const ConvertToKebabCase = (text) => {
  // Ensure text is a string and preprocess to handle camelCase/PascalCase
  const formattedText = text
    ? text.replace(/([a-z])([A-Z])/g, "$1-$2") // Insert hyphen before capital letters
    : "";

  return slugify(formattedText, {
    lower: true, // Convert to lowercase
    strict: true, // Remove special characters
  });
};

// for convert from kebeb case to normal
export const kebabToNormal = (kebabString) =>
  kebabString
    ?.split("-") // Split the string by hyphens
    .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize each word
    .join(" ");

// for formatting time
export const formattedTime = (time) =>
  time ? moment(time).format("YYYY-MM-DD / HH:mm:ss") : "";

// for formatting date
export const formatDateValue = (dateString) => {
  return moment(dateString).format("YYYY-MM-DD");
};

// for getting ISO Date
export const getISODate = (date = new Date()) => {
  return new Date(date).toISOString();
};

export const generateColumns = (data) => {
  if (!data || data.length === 0) return [];

  const firstEntry = data[0];
  const columns = Object.keys(firstEntry).map((key) => {
    const column = {
      name: key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase()),
      value: key,
    };
    if (key === "sprayDoneBy" || key === "reviewedBy") {
      column.type = "esignButton";
    }
    return column;
  });

  return columns;
};

export const generateNestedColumnArray = (rows) => {
  const columnArray = [];

  // Add main level keys
  for (const key in rows) {
    if (
      key === "children" &&
      Array.isArray(rows[key]) &&
      rows[key].length > 0
    ) {
      // Handle children array
      const childKeys = Object.keys(rows[key][0]);
      const childrenArray = childKeys.map((childKey) => ({
        name: childKey
          .replace(/([A-Z])/g, " $1") // Add space before capital letters
          .replace(/^./, (str) => str.toUpperCase()), // Capitalize first letter
        value: childKey,
        type:
          childKey === "targetDate"
            ? "date"
            : childKey === "status"
            ? "dropDown"
            : "text", // Add type based on childKey
      }));
      columnArray.push({
        name: "Children",
        value: childrenArray,
      });
    } else {
      // Handle other keys
      columnArray.push({
        name: key
          .replace(/([A-Z])/g, " $1") // Add space before capital letters
          .replace(/^./, (str) => str.toUpperCase()), // Capitalize first letter
        value: key,
        type: "text",
      });
    }
  }

  return columnArray;
};
