import { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "store";
import useConfig from "hooks/useConfig";
import { HORIZONTAL_MAX_ITEM } from "config";
import { MenuOrientation } from "config";
import {
  Home3,
  Profile2User,
  Calendar1,
  Award,
  UserTag,
  TableDocument,
  DocumentText,
  Messages2,
  Record,
  MenuBoard,
  Note,
  Judge,
  Box1,
  Grid3,
  More,
} from "iconsax-react";
import HistoryIcon from "@mui/icons-material/History";
import ReportIcon from "@mui/icons-material/Report";
import {
  checkPermission,
  ConvertToKebabCase,
  getItem,
  getPermssionDataByName,
} from "services/utils";
import { FeedbackTwoTone } from "@mui/icons-material";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import withRouter from "components/withRouter";

// icons
const icons = {
  dashboard: Home3,
  client: Profile2User,
  standard: Award,
  roles: UserTag,
  Record: Record,
  ReportIcon: ReportIcon,
  MenuBoard: MenuBoard,
  table: TableDocument,
  calendar: Calendar1,
  history: HistoryIcon,
  Judge: Judge,
  documentText: DocumentText,
  Note: Note,
  FeedbackTwoTone: FeedbackTwoTone,
  Messages2: Messages2,
  Box: Box1,
  Grid: Grid3,
  finish: CheckCircleOutlineIcon,
  general: AutoAwesomeMosaicIcon,
  more: More,
  ChangeHistoryIcon: ChangeHistoryIcon,
};

const Navigation = (props) => {
  const { theme, Constants, userData, FormatConstants, routeConstants } = props;

  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);

  const [selectedItems, setSelectedItems] = useState("");
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [menuItems, setMenuItems] = useState({ items: [] });
  const [permissionData, setPermissionData] = useState([]);

  // Function to generate contents based on pathname and userData
  const generateContents = (userData) => {
    let baseContents;
    if (userData?.externalUser) {
      baseContents = {
        id: "contents",
        title: "",
        type: "group",
        children: [],
      };
    } else {
      baseContents = {
        id: "contents",
        title: "Contents",
        type: "group",
        children: [
          {
            id: "Change Note",
            title: "Change Note",
            type: "item",
            url: "/change-note/change-note-history",
            icon: icons.ChangeHistoryIcon,
            permissionKey: null,
          },
          {
            id: "Users",
            title: "Users",
            type: "item",
            url: "/users",
            icon: icons.client,
            permissionKey: null,
          },
          {
            id: "Standards",
            title: "Standards",
            type: "item",
            url: "/standards/standard-list",
            icon: icons.standard,
            permissionKey: null,
          },
          {
            id: "Dynamic Document",
            title: "Dynamic Document",
            type: "item",
            url: "/dynamic-document/manage",
            icon: icons.table,
            permissionKey: null,
          },
          {
            id: "Master Table",
            title: "Master Table",
            type: "item",
            url: "/master-table",
            icon: icons.table,
            permissionKey: null,
          },
          {
            id: "Products",
            title: "Master Products",
            type: "item",
            url: "/product/list",
            icon: icons.Box,
            permissionKey: null,
          },
          {
            id: "Finished Product Register",
            title: "Finished Product Register",
            type: "item",
            url: "/finished-product-register/view",
            icon: icons.finish,
            permissionKey:
              FormatConstants.FINISHED_REGISTER_PRODUCT
                .FINISHED_PRODUCT_REGISTER,
          },
          {
            id: "Permissions",
            title: "Permissions",
            type: "item",
            url: "/permission",
            icon: icons.table,
            permissionKey: null,
          },
          {
            id: "comptency-matrix",
            title: "Competency Matrix",
            type: "item",
            url: `/${routeConstants.CAT}/view/${ConvertToKebabCase(
              FormatConstants.CAT.COMPETENCY_MATRIX
            )}`,
            icon: icons.Box,
            permissionKey: FormatConstants.CAT.COMPETENCY_MATRIX,
          },
          {
            id: "training-calender",
            title: "Training Calender",
            type: "item",
            url: `/${routeConstants.CAT}/view/${ConvertToKebabCase(
              FormatConstants.CAT.TRAINING_CALENDAR
            )}`,
            icon: icons.calendar,
            permissionKey: FormatConstants.CAT.TRAINING_CALENDAR,
          },
          {
            id: "Calendar",
            title: "Calendar",
            type: "item",
            url: "/calendar",
            icon: icons.calendar,
            permissionKey: null,
          },
          {
            id: "General",
            title: "General",
            type: "collapse",
            icon: icons.general,
            children: [
              {
                id: "Change Note Report",
                title: "Change Note Report",
                type: "item",
                url: "/changeNoteHistory",
                permissionKey: null,
              },

              {
                id: "Induction Training Records",
                title: "Induction Training Records",
                type: "item",
                url: `/${routeConstants.CAT}/list/${ConvertToKebabCase(
                  FormatConstants.CAT.INDUCTION_TRAINING_RECORD
                )}?searchByName=true`,
                permissionKey: FormatConstants.CAT.INDUCTION_TRAINING_RECORD,
              },
              {
                id: "Requisition for Man-Power",
                title: "Requisition for Man-Power",
                type: "item",
                url: `/${routeConstants.CAT}/list/${ConvertToKebabCase(
                  FormatConstants.CAT.REQUISITION_FOR_MANPOWER
                )}?searchByName=true`,
                permissionKey: null,
              },
            ],
          },
        ],
      };
    }
    const updatedContents = baseContents?.children
      ?.map((child) => {
        if (child.type === "item") {
          // Keep item if no permission is required or if user has the required permission
          return child.permissionKey === null ||
            checkPermission(permissionData, child.permissionKey)
            ? child
            : null;
        } else if (child.type === "collapse") {
          // Filter children inside collapse
          const filteredChildren = child?.children?.filter(
            (sub) =>
              sub?.permissionKey === null ||
              checkPermission(permissionData, sub.permissionKey)
          );

          // Keep collapse if it has at least one valid child
          const updatedChild = { ...child, children: filteredChildren ?? [] };
          return updatedChild;
        }
        return child;
      })
      .filter(Boolean); // Remove null values
    return { ...baseContents, children: updatedContents };
  };

  // let userData = getItem(Constants.KEY_USER_DATA);
  // let contents = generateContents(userData);

  // useEffect(() => {
  //   let data = {
  //     items: [...menuItem.items, contents],
  //   };
  //   setMenuItems(data);
  //   // eslint-disable-next-line
  // }, [menuItem, location?.pathname]);

  useEffect(() => {
    let userData = getItem(Constants.KEY_USER_DATA);
    let contents;
    if (userData?.externalUser) {
      contents = generateContents(userData);
    } else if (!userData?.externalUser && permissionData?.length > 0) {
      contents = generateContents(userData);
    }

    let data = {
      items: [...menuItem.items, contents],
    };
    setMenuItems(data);
  }, [permissionData, menuItem, location?.pathname]); // Runs only when permissionData is set

  useEffect(() => {
    userData && !userData?.externalUser && getPermissionData();
  }, []);

  const getPermissionData = async () => {
    let result = await getPermssionDataByName([
      FormatConstants.FINISHED_REGISTER_PRODUCT.FINISHED_PRODUCT_REGISTER,
      FormatConstants.CAT.COMPETENCY_MATRIX,
      FormatConstants.CAT.TRAINING_CALENDAR,
      FormatConstants.CAT.INDUCTION_TRAINING_RECORD,
      FormatConstants.CAT.REQUISITION_FOR_MANPOWER,
    ]);
    setPermissionData(result?.length > 0 ? result : []);
  };

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems?.items?.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItems?.items?.length) {
    lastItemId = menuItems?.items[lastItem - 1]?.id;
    lastItemIndex = lastItem - 1;
    remItems = menuItems?.items
      .slice(lastItem - 1, menuItems?.items?.length)
      .map((item, index) => ({
        index: index,
        title: item?.title,
        elements: item?.children,
        icon: item?.icon,
      }));
  }

  const navGroups = menuItems?.items
    ?.slice(0, lastItemIndex + 1)
    .map((item, index) => {
      switch (item?.type) {
        case "group":
          return (
            <NavGroup
              key={index}
              setSelectedItems={setSelectedItems}
              setSelectedLevel={setSelectedLevel}
              selectedLevel={selectedLevel}
              selectedItems={selectedItems}
              lastItem={lastItem}
              remItems={remItems}
              lastItemId={lastItemId}
              item={item}
            />
          );
        default:
          return (
            <Typography
              key={index}
              variant="h6"
              color="error"
              align="center"
            >
              {/* Fix - Navigation Group */}
            </Typography>
          );
      }
    });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        "& > ul:first-of-type": { mt: 0 },
        display: isHorizontal ? { xs: "block", lg: "flex" } : "block",
      }}
    >
      {navGroups}
    </Box>
  );
};

export default withRouter(Navigation);
