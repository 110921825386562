import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { Fragment, useEffect, useState } from "react";
import * as url from "../utils/urlHelper";
import * as equipmentUrl from "../../Mam-of-equipment/utils/urlHelper";
import { Button } from "@mui/material";
import {
  processInspectionDataWithRange,
  transformEquipmentData,
} from "../utils/utils";
import {
  buildUrl,
  getDocumentLifeCycleForMasterDoc,
  getFormatDataById,
  showToast,
} from "services/utils";
import {
  FormatConstants,
  MasterDocumentTypeConstants,
} from "services/keyConstants";
import ReportDynamicTable from "../ReportDynamicTable";

const InProcessPlanForm = (props) => {
  const { ApiServices } = props,
    { navigate, params } = props?.router;
  const [isOpen, setIsOpen] = useState(false);
  const { id } = params;
  const [documentData, setDocumentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [formatData, setFormatData] = useState({});
  const [tableColumns, setTableColumns] = useState([]);
  const [planColumns, setPlanColumns] = useState([]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    getDataById();
  }, [id]);

  const getFormatData = async () => {
    try {
      let result = await getFormatDataById(
        FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_REPORT
      );
      if (result?.structuredData?.length === 2) {
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPlanFormatData = async () => {
    try {
      let result = await getFormatDataById(
        FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN
      );
      if (result?.structuredData?.length === 1) {
        const newColls = result?.structuredData?.[0]?.columns?.map((col) => {
            if (!col?.source) {
                return {
                    ...col,
                    source: "parameter",
                    type: "text",
                    isReadOnly: true
                }
            } else {
                return { ...col, isReadOnly: true }
            }
        });
        setPlanColumns(newColls);
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    }
  };
  // get data by id
  const getDataById = async () => {
    try {
      setIsLoading(true);
      const uri = url.GET_INSPECTION_DATA_BY_ID + id;
      let result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        const processData = result?.data?.productData?.processData;
        let sampleSizeData = result?.meta?.sampleSizeData?.document ?? null;
        let finalUpdatedRows = processInspectionDataWithRange(
          processData,
          sampleSizeData ?? []
        );
        setData(finalUpdatedRows);
        setDocumentData({
          ...result?.data,
          sampleSizeData: sampleSizeData ?? [],
        });
        const productData = result?.data?.productData;
        const columnsFormatData = await getFormatData();
        if (productData?.columns?.length > 0) {
          setTableColumns(productData?.columns);
        } else {
          await getPlanFormatData();
          await setColumnsData(productData);
        }
        const columnsFormData = columnsFormatData?.structuredData?.[0]?.columns;
        let updated = columnsFormData?.map((column) => {
          const matchingProductData = productData[column?.value];
          if (column?.value === "reportNumber") {
            return {
              ...column,
              inputValue: result?.data?.reportNumber,
            };
          }
          return {
            ...column,
            inputValue:
              matchingProductData !== undefined ? matchingProductData : null,
          };
        });
        setFormColumns(updated);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setColumnsData = async (productData) => {
    const columnsFormatData = await getFormatData();
    let columnData = columnsFormatData?.structuredData?.[1];
    setFormatData(columnsFormatData);
    const equipmentData = await getInstrumentData();
    const updatedColumns = columnData?.columns?.map((column) => {
      if (
        column?.source === "parameter" &&
        column?.value === "equipmentDetails"
      ) {
        return { ...column, options: equipmentData };
      } else {
        return column;
      }
    });
    setTableColumns(updatedColumns);
  };

  // instrument api data
  const getInstrumentData = async () => {
    try {
      setIsLoading(true);
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.MAMOFEQUIPMENT.MASTERLIST_OF_EQUIPMENT,
        MasterDocumentTypeConstants.FORMAT
      );
      const queryParams = {
        masterDocId: masterDocData?._id,
        // year: "2025",
      };
      const uri = buildUrl(url.GET_BY_MASTERDOCID_BY_EQUIPMENT, queryParams);
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        // make array of object for equipment name and id merge
        let data = transformEquipmentData(result?.data[0]?.document);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // for save and edit api call
  const handleSave = async (updatedRecord, index, columns) => {
    const masterDocId = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.NON_CONFORMING_OF_PRODUCT.NON_CONFORMING_OF_PRODUCT,
      MasterDocumentTypeConstants.FORMAT
    );

    const filteredDataObservations =
      updatedRecord?.parametersToBeInspected?.map((param) => {
        return {
          ...param,
          observations: param?.observations?.filter(
            (obs) => obs !== null && obs !== undefined && obs !== ""
          ),
        };
      });

    let reqObj = {
      id: documentData?._id,
      index: index,
      updateData: {
        [`productData.columns`]: columns,
        [`productData.processData.${index}`]: {
          ...updatedRecord,
          parametersToBeInspected: filteredDataObservations,
        },
        [`productData.formColumns`]: formColumns,
      },
      bmrModelId: documentData?.productData?.bmrId,
      documentName: "In Process Inspection Report",
      masterDocIdForNonConfirming: masterDocId?._id,
      prefixType: "FormatPrefixes",
      reportPrefixType: "MAMOfInProcessReportPrefixes",
    };

    const result = await ApiServices.callServicePutWithBodyData(
      url.UPDATE_SPECIFIC_BY_ID,
      reqObj
    );
    showToast(result);
    if (result?.meta?.success) {
      const channel = new BroadcastChannel("bmrChannel");
      // Broadcast the event to all tabs
      channel.postMessage({ type: "bmrUpdate", data: result });
      getDataById();
    }
  };
  return (
    <MainCard
      title="In Process Plan Form"
      secondary={
        <>
          <Button
            color="success"
            variant="contained"
            onClick={() =>
              navigate(
                `/production/batch-manufacturing-record?id=${documentData?.productData?.bmrId}`
              )
            }
          >
            Show BMR
          </Button>
        </>
      }
    >
      <ReportDynamicTable
        tableColumns={[
          ...(planColumns?.length > 0 ? planColumns : []),
          ...(tableColumns?.length > 0 ? tableColumns : []),
        ]}
        setTableColumns={setTableColumns}
        formColumns={formColumns}
        setFormColumns={setFormColumns}
        rows={data}
        setRows={setData}
        handleEsign={isOpen}
        setHandleEsign={setIsOpen}
        handleSubmit={handleSave}
        errors={errors}
        setErrors={setErrors}
        documentData={documentData}
        setDocumentData={setDocumentData}
        formatData={formatData}
        setFormatData={setFormatData}
      />
    </MainCard>
  );
};

export default withRouter(InProcessPlanForm);
