import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Autocomplete,
  FormHelperText,
  Switch,
  Typography,
  Card,
  CardMedia,
  FormGroup,
  Checkbox,
  Rating
} from "@mui/material";
import { Edit, Eye, SaveAdd, Trash } from "iconsax-react";
import CustomTooltip from "./CustomTooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { extractUserIdAndName, toCamelCase } from "services/utils";
import DynamicNotification from "components/DynamicNotification";
import dayjs from "dayjs";
import withRouter from "components/withRouter";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import service from "services/constant";

const DynamicTableForm = ({
  columns,
  setColumns,
  isStatic = false,
  isViewMode = false,
  isNumberDynamic = false,
  viewMedia = "",
  isStrictData = false,
  isStrictDataForRow = false,
  errors,
  setErrors,
  isConditionalDate = false,
  handleESign,
  data,
  tableName = "",
  index,
}) => {
  const [editingCell, setEditingCell] = useState(null);
  const [editingHeader, setEditingHeader] = useState(null);
  const [headerInput, setHeaderInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedField, setSelectedField] = useState("");

  const deleteColumn = (colValue) => {
    setColumns(columns?.filter((column) => column.value !== colValue));
  };

  const handleCellChange = (e, columnValue) => {
    const updatedColumns = columns?.map((col) =>
      col.value === columnValue ? { ...col, inputValue: e.target.value } : col
    );
    setColumns(updatedColumns);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const startEditingCell = (colValue) => {
    setEditingCell(colValue);
  };

  const saveEditingCell = () => {
    setEditingCell(null);
  };

  const startEditingHeader = (colIndex, currentName) => {
    setEditingHeader(colIndex);
    setHeaderInput(currentName);
  };

  const saveEditingHeader = (colIndex) => {
    const oldColumn = columns[colIndex];
    const newColumnValue = toCamelCase(headerInput);

    const updatedColumns = [...columns];
    updatedColumns[colIndex] = { name: headerInput, value: newColumnValue };

    setColumns(updatedColumns);
    setEditingHeader(null);
    setHeaderInput("");
  };

  const handleDropdownChange = (e, columnValue) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === columnValue ? { ...col, inputValue: e.target.value } : col
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const handleDocumentUpload = (e, columnValue) => {
    const file = e.target.files;
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === columnValue ? { ...col, inputValue: file } : col
      )
    );
  };

  const handleDateChange = (date, columnValue) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === columnValue
          ? { ...col, inputValue: date?.toISOString() }
          : col
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const handleRadioChange = (e, columnValue) => {
    const value = e?.target?.value;
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === columnValue ? { ...col, inputValue: value } : col
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const handleCheckboxChange = (e, columnValue) => {
    const { value, checked } = e.target;

    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === columnValue
          ? {
            ...col,
            inputValue: col.inputValue.map((item) =>
              item.responsibility === value
                ? { ...item, checked } // Update checked state
                : item
            ),
          }
          : col
      )
    );

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const handleSwitchChange = (e, columnValue, col) => {
    const { checked } = e?.target;

    // If there is a linked value, we need to also update the linked column
    if (col?.linkedValue) {
      // Find the linked column
      let linkedColumn = columns?.find(
        (item) => item?.defaultName === col?.linkedValue
      );

      if (linkedColumn) {
        // Update the linked column with the same checked value
        setColumns((prevColumns) =>
          prevColumns?.map((colItem) =>
            colItem?.value === linkedColumn?.value
              ? { ...colItem, inputValue: checked } // Set the linked column's inputValue to checked
              : colItem
          )
        );
      }
    }

    // Update the current column with the checked value
    setColumns((prevColumns) =>
      prevColumns?.map((colItem) =>
        colItem?.value === columnValue
          ? { ...colItem, inputValue: checked } // Update the column being toggled
          : colItem
      )
    );

    // Clear any errors related to the column being toggled
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue]; // Remove the error for this column
      }
      return newErrors;
    });
  };

  const handleModal = (e, columnValue) => {
    setSelectedField(columnValue);
    setIsOpen(true);
  };

  const handleSubmitModalData = (selectedUser) => {
    if (!selectedUser) return;
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.value === selectedField
          ? {
            ...col,
            inputValue: extractUserIdAndName(selectedUser),
          }
          : col
      )
    );
  };

  const handleFileUpload = (files, columnValue) => {
    const uploadedFiles = Array.from(files); // Convert FileList to an array

    // Update columns state with the uploaded files
    setColumns((prevColumns) =>
      prevColumns?.map((col) =>
        col?.value === columnValue ? { ...col, inputValue: uploadedFiles } : col
      )
    );

    // Clear errors for this column if any
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[columnValue]) {
        delete newErrors[columnValue];
      }
      return newErrors;
    });
  };

  const getFileExtension = (path) => {
    return path?.split(".").pop().toLowerCase();
  };

  const FilePreview = ({ files }) => {
    return (
      <Box display="flex" flexWrap="wrap" gap={2}>
        {files?.map((file, index) => {
          const extension = getFileExtension(file?.imgPath || file?.name);
          if (!file?.imgPath && !file?.blobUrl) {
            file.blobUrl = URL.createObjectURL(file);
          }

          const imageSource = file?.imgPath
            ? service?.IMG_URL + file?.imgPath
            : file?.blobUrl;

          return (
            <a
              key={index}
              href={imageSource}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Card
                sx={{
                  width: 200,
                  height: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                }}
              >
                {["jpeg", "jpg", "png", "gif"].includes(extension) ? (
                  <CardMedia
                    component="img"
                    image={imageSource}
                    alt={extension}
                    sx={{
                      width: "80%",
                      height: "auto",
                      objectFit: "contain",
                      maxHeight: "100%",
                    }}
                  />
                ) : extension === "pdf" ? (
                  <Box
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <PictureAsPdfIcon
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        fontSize: 20,
                        color: "red",
                      }}
                    />
                    <iframe
                      src={imageSource}
                      title="PDF Preview"
                      style={{ width: "100%", height: "100%" }}
                    ></iframe>
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <InsertDriveFileIcon sx={{ fontSize: 50, color: "gray" }} />
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, textAlign: "center" }}
                    >
                      File Preview Not Available
                    </Typography>
                  </Box>
                )}
              </Card>
            </a>
          );
        })}
      </Box>
    );
  };

  // Helper function to get the display value
  const getDisplayValue = (col, colIndex, isNumberDynamic) => {
    if (colIndex === 0 && isNumberDynamic) {
      return colIndex + 1;
    }

    if (col?.isReadOnly) {
      if (col?.defaultValue?.dynamicField) {
        // Handle dynamic field scenario
        return (
          col?.defaultValue?.defaultValue?.[col?.displayValue] ??
          col?.defaultValue?.[col?.displayValue] ??
          col?.defaultValue[col?.displayValue] ??
          col?.inputValue ??
          ""
        );
      } else {
        // Handle normal default value scenario
        return (
          col?.defaultValue?.[col?.displayValue] ??
          col?.inputValue ??
          ""
        );
      }
    }

    // Handle editable or regular field scenario
    if (col?.displayValue) {
      return (
        col?.inputValue?.[col?.displayValue] ??
        col?.inputValue ??
        ""
      );
    }

    return col?.inputValue ?? "";
  };

  return (
    <>
      {isOpen ? (
        <DynamicNotification
          open={isOpen}
          setOpen={setIsOpen}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitModalData}
        />
      ) : null}
      <TableContainer component={Paper} className="temp">
        <h3>
          {(data?.isTableNameDisplay && data?.tableName) || tableName !== ""
            ? tableName
            : ""}
        </h3>
        <Table aria-label="simple table">
          <TableBody>
            {columns?.map((col, colIndex) => {
              // Determine if the column should be hidden
              let isHidden = false;

              if (
                col?.hidden?.key &&
                col?.hidden?.value &&
                col?.hidden?.checkingField
              ) {
                const hiddenCheck = columns?.find(
                  (item) =>
                    item?.[col?.hidden?.checkingField] === col?.hidden?.key
                )?.inputValue;

                isHidden = hiddenCheck !== col?.hidden?.value;
              } else if (col?.isHidden) {
                isHidden = true;
              }

              if (isHidden) {
                return null;
              }

              return (
                <TableRow key={colIndex}>
                  <TableCell>
                    {editingHeader === colIndex ? (
                      !isStatic && (
                        <Box display="flex" alignItems="center">
                          <TextField
                            value={headerInput}
                            onChange={(e) => setHeaderInput(e.target.value)}
                            onBlur={() => saveEditingHeader(colIndex)}
                            autoFocus
                          />
                          <IconButton
                            onClick={() => saveEditingHeader(colIndex)}
                            style={{ marginLeft: "10px" }}
                          >
                            <SaveAdd />
                          </IconButton>
                        </Box>
                      )
                    ) : (
                      <Box display="flex" alignItems="center">
                        {col?.isHeading ? "" : col?.name}
                        {!isViewMode && !isStatic && !isStrictDataForRow && (
                          <IconButton
                            color="success"
                            onClick={() =>
                              startEditingHeader(colIndex, col?.name)
                            }
                            style={{ marginLeft: "10px" }}
                          >
                            <Edit />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell key={colIndex}>
                    {col?.type === "radio" ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={col?.inputValue || ""}
                          onChange={(e) => handleRadioChange(e, col?.value)}
                        >
                          {col?.options?.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                        {errors?.[col?.value] && (
                          <FormHelperText style={{ color: "red" }}>
                            {errors?.[col?.value]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : col?.type === "checkbox" ? (
                      <FormControl component="fieldset">
                        <FormGroup >
                          {col?.options?.map((option, index) => {
                            const isChecked = col?.inputValue?.find(
                              (item) => item.responsibility === option
                            )?.checked;

                            return (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={isChecked || false}
                                    onChange={(e) => handleCheckboxChange(e, col?.value)}
                                    value={option}
                                    disabled={isViewMode || col?.isReadOnly ? true : false}
                                  />
                                }
                                label={option}
                              />
                            );
                          })}
                        </FormGroup>
                        {errors?.[col?.value] && (
                          <FormHelperText style={{ color: "red" }}>
                            {errors?.[col?.value]}
                          </FormHelperText>
                        )}
                      </FormControl>

                    ) : col?.type === "customInput" ? (
                      Array.isArray(col?.inputValue) ?
                        <>
                          {col?.inputValue?.map((list, listIndex) => {
                            const valueParts = col?.value?.split("-");
                            const dynamicValue = valueParts
                              .map((part) => list[part]) // Fetch values dynamically from the list object
                              .join(" - ");

                            return (
                              <FormControl sx={{ margin: 1 }} key={listIndex} fullWidth>
                                <TextField
                                  label={`Enter ${col?.name}`}
                                  type="text"
                                  value={dynamicValue}
                                  onBlur={saveEditingCell}
                                  InputProps={{
                                    readOnly: isViewMode || col?.isReadOnly ? true : false,
                                  }}
                                  onChange={(e) => handleCellChange(e, col?.value)}
                                  autoFocus
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              </FormControl>
                            );
                          })}

                        </>
                        : <>
                          <FormControl fullWidth>
                            <TextField
                              rows={col?.inputValue}
                              label={`Enter ${col?.name}`}
                              type={col?.type ? col?.type : "text"}
                              value={col?.inputValue || ""}
                              onChange={(e) => handleCellChange(e, col?.value)}
                              onBlur={saveEditingCell}
                              InputProps={{
                                readOnly: isViewMode || col?.isReadOnly ? true : false,
                              }}
                              sx={{
                                width: "100%",
                              }}
                            />
                          </FormControl>
                        </>
                    ) : col?.type === "date" ? (
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            name="dateOfRequest"
                            sx={{
                              minWidth: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                            }}
                            value={
                              col?.inputValue ? dayjs(col?.inputValue) : null
                            }
                            format="DD/MM/YYYY"
                            onChange={(date) =>
                              handleDateChange(date, col?.value)
                            }
                            minDate={isConditionalDate ? dayjs() : null}
                            readOnly={isViewMode || col?.isReadOnly}
                            textField={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                          />
                          {errors?.[col?.value] && (
                            <span style={{ color: "red" }}>
                              {errors?.[col?.value]}
                            </span>
                          )}
                        </LocalizationProvider>
                      </FormControl>
                    ) : col?.type === "esignButton" ? (
                      col?.inputValue?.userData?.name ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={`${col?.inputValue?.userData?.name} - ${dayjs(
                            col?.inputValue?.time
                          ).format("DD/MM/YYYY HH:mm:ss")}`}
                          InputProps={{ readOnly: true }}
                        />
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleESign(col?.value)}
                          >
                            ESign
                          </Button>
                          <FormHelperText error>
                            {errors?.[col?.value] || ""}
                          </FormHelperText>
                        </>
                      )
                    ) : col?.type === "dropdown" ? (
                      <>
                        <FormControl fullWidth>
                          <InputLabel>Select Option</InputLabel>
                          <Select
                            multiple={col?.isMultiple}
                            sx={{
                              minWidth: "100px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: errors?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                            }}
                            value={
                              col?.inputValue
                                ? col?.inputValue
                                : col?.isMultiple
                                  ? []
                                  : ""
                            }
                            onChange={(e) => {
                              const selectedValue = e.target.value;

                              handleDropdownChange(e, col?.value);

                              // If there's an `extraOnChange` function, execute it (optional).
                              if (col?.extraOnChange) {
                                col?.extraOnChange(e, col?.value, columns);
                              }
                            }}
                            readOnly={col?.isReadOnly}
                            fullWidth
                            disabled={isViewMode} // Disable the dropdown if it's in view mode
                          >
                            {col?.options?.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option[col.renderOptionValue] ?? option} // Ensure the correct value is used
                              >
                                {option[col.renderOptionValue] ?? option}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors?.[col?.value] && (
                            <span style={{ color: "red" }}>
                              {errors?.[col?.value]}
                            </span>
                          )}
                        </FormControl>
                        {col?.isDocumentAdd &&
                          col?.inputValue === col?.condition &&
                          (isViewMode ? (
                            <CustomTooltip title="View Document">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  isViewMode && viewMedia(col?.inputValue)
                                }
                              >
                                <Eye />
                              </IconButton>
                            </CustomTooltip>
                          ) : (
                            <div style={{ marginTop: "10px" }}>
                              <label htmlFor={`upload_${col?.value}`}>
                                Upload Document (Photo/Video)
                              </label>
                              <input
                                type="file"
                                multiple
                                id={`upload_${col?.value}`}
                                accept="image/*,video/*"
                                onChange={(e) =>
                                  handleDocumentUpload(e, col?.value)
                                }
                              />
                            </div>
                          ))}
                      </>
                    ) : col?.type === "files" ? (
                      <>
                        {!isViewMode && (
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              htmlFor={`upload-${col?.value}`}
                            >
                              {col?.name}
                            </InputLabel>
                            <TextField
                              id={`upload-${col?.value}`}
                              type="file"
                              inputProps={{
                                multiple: col?.isMultiple, // Allow multiple file uploads if specified
                                accept: ".pdf, .jpg, .jpeg, .png", // Restrict file types if needed
                              }}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: errors?.[col?.value]
                                    ? "red"
                                    : "default",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: errors?.[col?.value]
                                    ? "red"
                                    : "default",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: errors?.[col?.value]
                                    ? "red"
                                    : "default",
                                },
                              }}
                              onChange={(e) => {
                                const files = e?.target?.files;
                                const modifiedFiles = Array.from(files).map(
                                  (file) => {
                                    // Create a new file name by appending 'hello' to the original name
                                    const newFileName = `${col?.defaultName}_${file.name}`;
                                    // Create a new File object with the modified name
                                    const modifiedFile = new File(
                                      [file],
                                      newFileName,
                                      { type: file.type }
                                    );

                                    return modifiedFile;
                                  }
                                );

                                handleFileUpload(modifiedFiles, col?.value); // A function to handle file upload logic
                              }}
                              disabled={isViewMode} // Disable the file input if it's in view mode
                              fullWidth
                              error={Boolean(errors?.[col?.value])} // Highlight the field in case of errors
                              helperText={
                                errors?.[col?.value] && (
                                  <span style={{ color: "red" }}>
                                    {errors?.[col?.value]}
                                  </span>
                                )
                              }
                            />
                          </FormControl>
                        )}
                        {col?.inputValue && col?.inputValue?.length > 0 ? (
                          <Box p={4}>
                            <FilePreview files={col?.inputValue} />
                          </Box>
                        ) :
                          isViewMode && (
                            <Box
                              p={4}
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              border="1px dashed #ccc"
                              borderRadius={2}
                              sx={{
                                backgroundColor: "#f9f9f9",
                                minHeight: 100,
                                textAlign: "center",
                              }}
                            >
                              <Typography variant="body1" color="textSecondary">
                                No Document Found
                              </Typography>
                            </Box>
                          )}
                      </>
                    ) : col?.type === "dynamicButton" ? (
                      <Button
                        onClick={() =>
                          col?.isNotificationValue
                            ? handleModal(col?.value)
                            : null
                        }
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px", marginTop: "10px" }}
                      >
                        {col?.dynamicValue
                          ? col?.inputValue?.name ||
                          col?.dynamicValue?.name ||
                          ""
                          : col?.value}
                      </Button>
                    ) : col?.type === "dynamicDropdown" ? (
                      <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={
                          Array.isArray(col?.inputValue) ? col?.inputValue : []
                        }
                        onChange={(e, value) => {
                          const updatedColumns = [...columns];
                          updatedColumns[colIndex] = {
                            ...updatedColumns[colIndex],
                            inputValue: value,
                          };
                          setColumns(updatedColumns);
                          setErrors((prevErrors) => {
                            const newErrors = { ...prevErrors };
                            if (newErrors[col?.value]) {
                              delete newErrors[col?.value]; // Remove error if it exists
                            }
                            return newErrors;
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Enter ${col?.name}`}
                            helperText={errors?.[col?.value] || ""}
                            error={!!errors?.[col?.value]}
                          />
                        )}
                      />
                    ) : col?.type === "switch" ? (
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={col?.inputValue || false}
                              onChange={(e) => {
                                handleSwitchChange(e, col?.value, col);
                                if (col?.extraOnChange) {
                                  col?.extraOnChange(e, col?.value, columns);
                                }
                              }}
                              name={col?.value}
                            />
                          }
                        />
                        {errors?.[col?.value] &&
                          (col?.isErrorHidden ? null : (
                            <FormHelperText style={{ color: "red" }}>
                              {errors?.[col?.value]}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    ) : col?.type === "rating" ?
                      (
                        <Rating
                          value={col?.inputValue || ""}
                          onChange={(e) => handleCellChange(e, col?.value)}
                          precision={1}
                          max={col?.max ?? 5}
                          readOnly={isViewMode || col?.isReadOnly}
                        />
                      )
                      : editingCell && editingCell?.value === col?.value ? (
                        <TextField
                          multiline={col?.type === "textarea"}
                          rows={
                            col?.type === "textarea" ? col?.rows ?? 4 : undefined
                          }
                          label={`Enter ${col?.name}`}
                          type={col?.type ? col?.type : "text"}
                          value={col?.inputValue || ""}
                          error={!!errors?.[col?.value]}
                          helperText={errors?.[col?.value] || ""}
                          onChange={(e) => handleCellChange(e, col?.value)}
                          onBlur={saveEditingCell}
                          autoFocus
                          sx={{
                            width: "100%",
                            readOnly:
                              isViewMode || col?.isReadOnly ? true : false,
                          }}
                        />
                      ) : (

                        <Box
                          display="flex"
                          alignItems={col?.isHeading ? "center" : "flex-start"}
                          justifyContent={col?.isHeading ? "center" : "flex-start"}
                        >
                          {col?.type === "textarea" ? (
                            <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                              {getDisplayValue(col, colIndex, isNumberDynamic)}
                            </div>
                          ) : (
                            getDisplayValue(col, colIndex, isNumberDynamic)
                          )}

                          {errors?.[col?.value] && (
                            <span style={{ color: "red" }}>{errors?.[col?.value]}</span>
                          )}

                          {!isViewMode &&
                            (col?.isReadOnly === undefined || col?.isReadOnly === false) &&
                            !col?.isHeading && ( // Prevent the edit icon for headings
                              <IconButton
                                onClick={() => startEditingCell(col)}
                                style={{ marginLeft: "10px" }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                        </Box>

                      )}
                  </TableCell>
                  {!isViewMode &&
                    !isStrictData &&
                    !isStrictDataForRow &&
                    (col?.isDelete === false ? null : (
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => deleteColumn(col?.value)}
                          style={{ marginLeft: "10px" }}
                        >
                          <Trash />
                        </IconButton>
                      </TableCell>
                    ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withRouter(DynamicTableForm);
