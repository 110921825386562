import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

const MaintenanceError = Loadable(
  lazy(() => import("pages/maintenance/error/404"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/error/500"))
);

const Calendar = Loadable(lazy(() => import("components/Calendar")));
const Dashboard = Loadable(lazy(() => import("pages/Dashboard/Dashboard")));
const DashboardNew = Loadable(lazy(() => import("pages/Dashboard/DashboardNew")));
const FigmDashboard = Loadable(lazy(() => import("pages/Dashboard/FigmDashboard")));
const Notification = Loadable(
  lazy(() => import("pages/Notification/Notification"))
);
const AssignedDocs = Loadable(lazy(() => import("pages/Users/AssignDocs")));
const Question = Loadable(
  lazy(() => import("pages/StandardQuestion/Question"))
);
const UserLists = Loadable(lazy(() => import("pages/Users/UserLists")));
const MasterTable = Loadable(
  lazy(() => import("pages/MasterTable/MasterTable"))
);
const Profile = Loadable(lazy(() => import("pages/ProfilePage/Profile")));

const createRoute = (path, element, children) => ({ path, element, children });

const MainRoutes = {
  path: "/",
  children: [
    createRoute(
      "/",
      <AuthGuard>
        <MainLayout />
      </AuthGuard>,
      [
        createRoute("dashboard", <Dashboard />),
        createRoute("dashboard_new", <FigmDashboard />),
        // createRoute("dashboard_new", <DashboardNew />),
        createRoute("standards", null, [createRoute("docs", <AssignedDocs />)]),
        createRoute("standards-question", null, [
          createRoute("question", <Question />),
        ]),
        createRoute("users", <UserLists />),
        createRoute("profile", <Profile />),
        createRoute("master-table", <MasterTable />),
        createRoute("calendar", <Calendar />),
        createRoute("notification", <Notification />),
      ]
    ),
    createRoute("/maintenance", <CommonLayout />, [
      createRoute("404", <MaintenanceError />),
      createRoute("500", <MaintenanceError500 />),
    ]),
  ],
};

export default MainRoutes;
