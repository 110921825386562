import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper";
// Define lazy-loaded components
const InternalTable = Loadable(lazy(() => import("../InternalTable")));
const MasterListTable = Loadable(lazy(() => import("../MasterListTable")));
const History = Loadable(lazy(() => import("../History")));

const InternalCalibrationEquipmentList = Loadable(
  lazy(() => import("../InternalCalibrationEquipmentList"))
);
const EquipmentList = Loadable(lazy(() => import("../EquipmentList")));

const MAMOfEquipmentForm = Loadable(
  lazy(() => import("../MAMOfEquipmentForm"))
);
const InternalCalibrationEquipment = Loadable(
  lazy(() => import("../InternalCalibrationEquipment"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const MAMEquipmentRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.MAM_OF_EQUIPMENT}`, null, [
      createRoute("equipment-masterlist/:year?", <MasterListTable />),
      createRoute("equipment-internal/:year", <InternalTable />),
      createRoute(
        "monitoring-and-measurement-of-equipment-format",
        <History />
      ),
      createRoute(
        "internal-calibration-equipment-list",
        <InternalCalibrationEquipmentList />
      ),
      createRoute("list/:year", <EquipmentList />),
      createRoute(
        commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          title={routeConstants.MAM_OF_EQUIPMENT}
          isExtraButtons={true}
          extraButtonChildren={[
            {
              buttonTitle: " Master List Of Equipment",
              path: `/${routeConstants.MAM_OF_EQUIPMENT}/equipment-masterlist/:year?`,
            },
            // {
            //   buttonTitle: " Internal Calibration Report",
            //   path: `/${routeConstants.MAM_OF_EQUIPMENT}/internal-calibration-equipment/id`,
            // },
          ]}
          navigateToFolder={`/${routeConstants.MAM_OF_EQUIPMENT}/list/`}
        />
      ),
      createRoute("equipment-form", <MAMOfEquipmentForm />),
      createRoute(
        "internal-calibration-equipment/:id",
        <InternalCalibrationEquipment />
      ),
    ]),
  ]
);

export default MAMEquipmentRoutes;
