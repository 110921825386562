import {
  Box,
  CardContent,
  ClickAwayListener,
  Divider,
  IconButton,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Windows } from "iconsax-react";
import { useRef, useState } from "react";
import {
  camelCaseToString,
} from "services/utils";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import Waves from "assets/images/waves.svg";
import Dot from "components/@extended/Dot";
import DynamicNotification from "components/DynamicNotification";
import withRouter from "components/withRouter";

const MegaMenuSection = (props) => {
  const isSmallScreen = useMediaQuery("(min-width:1000px)");
  const {
    theme,
    routeConstants,
  } = props,
    { navigate } = props?.router,
    { ThemeMode, DRAWER_WIDTH } = props?.config;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const handleService = () => {
    navigate(`${routeConstants.CUSTOMER_SERVICE}/customer-service-form`);
  };

  const buttonData = {
    general: [
      {
        text: "Customer Service Form",
        onClick: handleService,
      },
      {
        text: "Feedback",
        onClick: () => navigate(`${routeConstants.FEEDBACK}/create`),
      },
      {
        text: "Internal communication",
        onClick: () => navigate(`${routeConstants.INTERNAL_COMMUNICATION}/create`),
      },
      {
        text: "Customer Property",
        onClick: () =>
          navigate(
            `${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}/list-of-customer-property`
          ),
      },
      {
        text: "Work Environment",
        onClick: () => navigate(`${routeConstants.WORK_ENVIRONMENT}/year-list`), 
      },
      {
        text: "Add-Complaint",
        onClick: () =>
          navigate(`${routeConstants.CUSTOMER_COMPLAINTS}/add-complaint`),
      },
      {
        text: "Generate Inprocess Record",
        onClick: () => navigate(`${routeConstants.MAM_OF_PRODUCT}/incoming`),
      },
      {
        text: "Change Note",
        onClick: () => navigate(`${routeConstants.CHANGE_NOTE}/manage`),
      },
      {
        text: "Communication With RegulatoryBody",
        onClick: () =>
          navigate(
            `/${routeConstants.COMMUNICATION_WITH_REGULATORY_BODIES}/communication-with-regulatory-body`
          ),
      },
    ],
    CAPA: [
      {
        text: "Corrective action",
        onClick: () => navigate("capa/corrective"),
      },
      {
        text: "Preventive Action",
        onClick: () => navigate("capa/preventive"),
      },
    ],
    customerRelatedProcessAndDistribution: [
      {
        text: "Generate New Agent",
        onClick: () => navigate(`${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-agent`),
      },
      {
        text: "Generate Order Form",
        onClick: () => navigate(`${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-order`),
      },
    ],
  };

  // Filter out falsy values for each key in buttonData
  Object.keys(buttonData)?.forEach((key) => {
    buttonData[key] = buttonData[key]?.filter(Boolean);

    // If no valid buttons, delete the key
    if (buttonData[key]?.length === 0) {
      delete buttonData[key];
    }
  });

  const createButton = (text, onClick, gradient1, gradient2, shadow) => ({
    text,
    onClick,
  });

  const createButtonsForCategory = (category) => {
    return (
      buttonData[category]
        ?.filter(Boolean)
        ?.map(({ text, onClick }) => createButton(text, onClick)) || []
    );
  };

  return (
    <>
      {isOpenNotification ? (
        <DynamicNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitMasterList}
        />
      ) : null}
      <Box sx={{ flexShrink: 0, ml: 0.5 }}>
        <IconButton
          color="secondary"
          variant="light"
          aria-label="open Btns"
          ref={anchorRef}
          aria-controls={open ? "Btns-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          size="large"
          sx={{
            color: "secondary.main",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            p: 1,
          }}
        >
          <Windows variant="Bulk" />
        </IconButton>

        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: { offset: isSmallScreen ? [170, 9] : [0, 9] },
              },
            ],
          }}
          sx={{
            maxHeight: "500px",
            overflow: "hidden",
          }}
        >
          {({ TransitionProps }) => (
            <Transitions
              type="grow"
              position="top-right"
              in={open}
              {...TransitionProps}
            >
              {isSmallScreen ? (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: {
                      md: `calc(100vw - 200px)`,
                      lg: `calc(100vw - ${DRAWER_WIDTH + 100}px)`,
                      xl: `calc(100vw - ${DRAWER_WIDTH + 140}px)`,
                    },
                    maxWidth: 1024,
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    overflow: "hidden",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <Grid container>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            color: "common.white",
                            bgcolor:
                              theme?.palette?.mode === ThemeMode?.DARK
                                ? "primary.400"
                                : "primary.darker",
                            position: "relative",
                            "&:after": {
                              content: '""',
                              background: `url("${Waves}") 100% / cover no-repeat`,
                              position: "absolute",
                              top: "41%",
                              left: 0,
                              right: 0,
                              bottom: 0,
                              zIndex: 1,
                              opacity: 0.5,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              p: 4.5,
                              pb: 3,
                              position: "inherit",
                              zIndex: 2,
                            }}
                          >
                            <Stack sx={{ color: "background.paper" }}>
                              <Typography
                                variant="h2"
                                sx={{ fontSize: "1.875rem", mb: 1 }}
                              >
                                Explore Features
                              </Typography>
                              <Typography variant="h6">
                                Explore our features to kickstart your work."
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box
                            sx={{
                              p: 4,
                              "& .MuiList-root": { pb: 0 },
                              "& .MuiListSubheader-root": { p: 0, pb: 1.5 },
                              "& .MuiListItemButton-root": {
                                p: 0.5,
                                "&:hover": {
                                  bgcolor: "transparent",
                                  "& .MuiTypography-root": {
                                    color: "primary.main",
                                  },
                                },
                              },
                              "& .MuiListItemIcon-root": { minWidth: 16 },
                            }}
                          >
                            <Grid
                              container
                              spacing={6}
                              sx={{
                                overflowY: "scroll",
                                maxHeight: "500px",
                                "&::-webkit-scrollbar": {
                                  width: "5px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            >
                              {Object?.keys(buttonData)?.map((category) => (
                                <Grid item xs={6} key={category}>
                                  <List
                                    component="nav"
                                    aria-labelledby={`nested-list-${category}`}
                                    subheader={
                                      <ListSubheader
                                        id={`nested-list-${category}`}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          color="text.primary"
                                          sx={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {camelCaseToString(category)}
                                        </Typography>
                                      </ListSubheader>
                                    }
                                  >
                                    {createButtonsForCategory(category)
                                      ?.length > 0
                                      ? createButtonsForCategory(category)?.map(
                                        (button, i) => (
                                          <ListItemButton
                                            key={i}
                                            onClick={(e) => {
                                              if (button?.onClick)
                                                button.onClick(e);
                                              handleClose(e);
                                            }}
                                          >
                                            <ListItemIcon>
                                              <Dot
                                                size={6}
                                                color="secondary"
                                                variant="outlined"
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={button?.text}
                                            />
                                          </ListItemButton>
                                        )
                                      )
                                      : null}
                                  </List>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              ) : (
                <Paper
                  sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    boxShadow: theme.customShadows.z1,
                    maxWidth: 350,
                    overflowY: "scroll",
                    maxHeight: "500px",
                    borderRadius: 1.5,
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard border={false} content={false}>
                      <CardContent sx={{ p: 2, pb: 0 }}>
                        {Object.keys(buttonData).map((category) => (
                          <Box key={category} sx={{ mb: 1 }}>
                            <Typography
                              variant="subtitle2"
                              color="text.primary"
                              sx={{
                                textTransform: "capitalize",
                                fontSize: "11px",
                                // mb: 1,
                              }}
                            >
                              {camelCaseToString(category)}
                            </Typography>
                            <Divider />
                            <List
                              component="nav"
                              sx={{
                                p: 0,
                                "& .MuiListItemIcon-root": { minWidth: 32 },
                                "& .MuiDivider-root": { my: 1 },
                              }}
                            >
                              {createButtonsForCategory(category)?.length > 0 &&
                                createButtonsForCategory(category)?.map(
                                  ({ text, onClick }, i) => (
                                    <ListItemButton
                                      key={i}
                                      onClick={(e) => {
                                        if (onClick) onClick(e);
                                        handleClose(e);
                                      }}
                                    >
                                      <ListItemText primary={text} />
                                    </ListItemButton>
                                  )
                                )}
                            </List>
                          </Box>
                        ))}
                      </CardContent>
                      <Divider />
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default withRouter(MegaMenuSection);
