import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { useEffect, useRef, useState } from "react";
import * as url from "../utils/urlHelper";
import DynamicTable from "../DynamicTable";
import { Box, Button, Tabs, Tab, Divider } from "@mui/material";
import {
  buildUrl,
  downloadFormat,
  getDocumentLifeCycleForMasterDoc,
  showToast,
  TabPanel,
} from "services/utils";
import IncomingInspectionPlan from "./IncomingInspectionPlan";
import { FormatConstants } from "services/keyConstants";

const MamGeneratePlan = (props) => {
  const {
    ApiServices,
    userData,
    onChangeDownloadState,
    // FormatConstants,
    MasterDocumentTypeConstants,
  } = props, { params } = props.router;
  const { year } = params;
  const [value, setValue] = useState(0);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };
  const [samplePlan, setSamplePlan] = useState([])
  const [procedures, setProcedures] = useState([]);
  const [inProcessInspection, setInProcessInspection] = useState([
    {
      documentName: "In process Inspection Plan",
      product: "",
      rows: [
        {
          process: "",
          parametersToBeInspected: [],
        },
      ],
    },
  ]);
  const [finalProcess, setFinalProcess] = useState([
    {
      documentName: "Final process Inspection Plan",
      product: "",
      rows: [
        {
          process: "",
          parametersToBeInspected: [],
        },
      ],
    },
  ]);

  const [productData, setProductData] = useState([]);
  const [errors, setErrors] = useState([]);
  const filterData = useRef({
    currentPage: 1,
    totalPage: 1,
  });
  const [documentIds, setDocumentIds] = useState({});
  const getPlanDetails = async () => {
    const reqObj = {
      page: filterData?.current?.currentPage,
      documentNames: [
        "Incoming Inspection Plan",
        "In process Inspection Plan",
        "Final process Inspection Plan",
      ],
    };
    let uri = url.GET_MONITORING_MEASUREMENT_PRODUCT;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    if (result?.meta?.success) {
      let docuIds = {};
      const fetchedResult = result?.data;
      fetchedResult?.forEach((item) => {
        const documentName = item?.documentName;
        if (documentName === "Final process Inspection Plan") {
          docuIds.finalProcessId = item?._id;
        } else if (documentName === "In process Inspection Plan") {
          docuIds.inProcessId = item?._id;
        } else if (documentName === "Incoming Inspection Plan") {
          docuIds.inComingInspectionId = item?._id;
        }
      });
      setDocumentIds(docuIds);
      filterData.current.totalPage = result?.meta?.totalPages;
    } else {
      filterData.current.currentPage = 1;
      filterData.current.totalPage = 1;
    }
  };

  const getParent = async () => {
    try {
      const reqObj = {
        orgId: userData?.orgId,
      };
      const uri = url.GET_PARENT;
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        setProductData(result?.data);

        const getData = (type) => {
          return result?.data?.map((dataItem) => {
            return dataItem?.[type]?.map((item) => ({
              ...item,
              processName: item?.processName,
              _id: item?._id,
            }));
          });
        };

        let obj = {
          inprocessData: getData("inprocessData"),
          finalData: getData("finalData"),
        };
        setProcedures(obj);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPlanDetails();
    getParent();
    getSmapleSizeMethods()
  }, []);

  const getSmapleSizeMethods = async () => {
    try {
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.MAMOFPRODUCT.SAMPLING_PLAN,
        MasterDocumentTypeConstants.FORMAT
      );
      const queryParams = {
        masterDocId: masterDocData?._id,
        year: year,
      };
      const uri = buildUrl(url.GET_BY_MASTERDOCID, queryParams);
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        setSamplePlan(result?.data)
      }
    } catch (error) {
      console.error("ERROR TO GET SAMPLE SIZE", error)
    }
  }

  const handleUpdate = async (type, data, getApi, selectedUsers) => {
    try {
      let reqObj = {};
      if (type == "Incoming Inspection Plan") {
        reqObj = {
          id: documentIds?.inComingInspectionId,
          updateData: {
            document: data,
          },
        };
      } else if (type == "In Process Inspection Plan") {
        reqObj = {
          id: documentIds?.inProcessId,
          updateData: {
            document: data,
            notifiedUsers: selectedUsers
          },
        };
      } else if (type == "Final Inspection Plan") {
        reqObj = {
          id: documentIds?.finalProcessId,
          updateData: {
            document: data,
            notifiedUsers: selectedUsers
          },
        };
      }
      let uri = url.UPDATE_MONITORING_MEASUREMENT_PRODUCT;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        await getApi()
        await getPlanDetails()
      }
    } catch (error) {
      console.error("ERROR TO UPDATE DATA", error);
    }
  };

  const handleSubmit = async (type, data, getApi, selectedUsers) => {
    try {
      let reqObj = {};
      let constant = "";
      if (type === "Incoming Inspection Plan") {
        constant = FormatConstants.MAMOFPRODUCT.INCOMING_INSPECTION_PLAN;
      } else if (type == "In Process Inspection Plan") {
        constant = FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN;
      } else if (type == "Final Inspection Plan") {
        constant = FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_Plan;
      }
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        constant,
        MasterDocumentTypeConstants.FORMAT
      );
      if (type == "Incoming Inspection Plan") {
        reqObj = {
          masterDocId: masterDocData?._id,
          documentName: "Incoming Inspection Plan",
          prefixType: "FormatPrefixes",
          document: data,
        };
      } else if (type == "In Process Inspection Plan") {
        reqObj = {
          masterDocId: masterDocData?._id,
          documentName: "In process Inspection Plan",
          prefixType: "FormatPrefixes",
          document: data,
          notifiedUsers: selectedUsers
        };
      } else if (type == "Final Inspection Plan") {
        reqObj = {
          masterDocId: masterDocData?._id,
          documentName: "Final process Inspection Plan",
          prefixType: "FormatPrefixes",
          document: data,
          notifiedUsers: selectedUsers
        };
      }

      // API call for submitting data based on the 'type'
      const uri = url.POST_MONITORING_MEASUREMENT_PRODUCT;
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      showToast(result); // Show success toast
      // Handle success response
      if (result?.meta?.success) {
        const fetchedResult = result?.result?.data;
        await getApi()
        await getPlanDetails()
      }
    } catch (error) {
      // Handle error case
      console.error("ERROR TO SUBMIT DATA", error);
    }
  };

  const handleDownload = async (mainRecordId) => {
    try {
      let reqObj = {
        formatId: mainRecordId,
      };
      let uri = url.DOWNLOAD_PLANS;
      await downloadFormat(uri, reqObj, onChangeDownloadState);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };
  const tabLabels = [
    "In Comming Inspection Plan ",
    "In Process Inspection Plan",
    "Final Inspection Plan",
  ];

  return (
    <MainCard title="Monitoring and Measurement of Product">
      <Box>
        <Tabs
          value={value}
          variant={"fullWidth"}
          onChange={handleChange}
          aria-label="tabs container"
        >
          {tabLabels?.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0}>
          <IncomingInspectionPlan
            handleDownload={() =>
              handleDownload(documentIds?.inComingInspectionId)
            }
            handleSubmit={documentIds?.inComingInspectionId
              ? handleUpdate
              : handleSubmit}
            year={year}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DynamicTable
            defaultRow={inProcessInspection}
            errors={errors}
            setErrors={setErrors}
            productData={productData}
            procedures={procedures}
            setProcedures={setProcedures}
            dataType={"In process Inspection Plan"}
            handleDownload={() => handleDownload(documentIds?.inProcessId)}
            samplePlan={samplePlan}
            handleSubmit={documentIds?.inProcessId
              ? handleUpdate
              : handleSubmit}
            year={year}
            buttonTitle={documentIds?.inProcessId ? "Update" : "Save"}
          />

        </TabPanel>
        <TabPanel value={value} index={2}>
          <DynamicTable
            defaultRow={finalProcess}
            errors={errors}
            setErrors={setErrors}
            setProcedures={setProcedures}
            productData={productData}
            procedures={procedures}
            dataType={"Final process Inspection Plan"}
            handleDownload={() => handleDownload(documentIds?.finalProcessId)}
            samplePlan={samplePlan}
            handleSubmit={documentIds?.finalProcessId
              ? handleUpdate
              : handleSubmit}
            year={year}
            buttonTitle={documentIds?.finalProcessId ? "Update" : "Save"}
          />

        </TabPanel>
      </Box>
    </MainCard>
  );
};

export default withRouter(MamGeneratePlan);
