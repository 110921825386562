import React, { useEffect, useState } from "react";
import * as url from "./Utils/urlHelper";
import withRouter from "components/withRouter";
import CustomNotification from "components/CustomNotification";
import GetProduct from "components/GetProduct";
import {
  checkPermission,
  getDocumentLifeCycleForMasterDoc,
  getPermssionDataByName,
  sendNotification,
  showToast,
} from "services/utils";
import { Button } from "@mui/material";
import { permissionConstants } from "services/keyConstants";

const RecallModal = (props) => {
  const {
    open,
    ApiServices,
    userData,
    FormatConstants,
    MasterDocumentTypeConstants,
    routeConstants
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [selectuserData, setSelectedUserData] = useState([]);
  const [responsibileUserModal, setResponsibileUserModal] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [permissionData,setPermissionData] = useState([]);

  const handleNext = () => {
    if (activeStep === 0) {
      setResponsibileUserModal(false); // Close CustomNotification dialog
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleSubmit = (selectedUser) => {
    setSelectedUserData(selectedUser);
    setResponsibileUserModal(false);
    setIsOpen(true);
    setActiveStep(1);
    handleNext();
  };

  const getSelectedProducts = async () => {
    try {
      const uri = url.GET_SELECTED_PRODUCT_LIST;
      const result = await ApiServices.callServiceGet(uri);
      
      if (result?.meta?.success) {
        setSelectedProductData(result?.data);
      }
    } catch (error) {
      console.error("ERROR TO GET SELECTED PRODUCT", error);
    }
  };

  const  getPermissionData = async()=>{
    let result = await getPermssionDataByName(
      FormatConstants.ADVISORY_NOTICE_AND_RECALL.ADVISORY_RECALL
    );
    setPermissionData(result ?? []);
  }

  useEffect(() => {
    if (open) {
      setActiveStep(0);
    }
    getSelectedProducts();
    getPermissionData();
  }, [  ]);

  const handleGetProduct = async (selectedData) => {
    let masterDocData = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.ADVISORY_NOTICE_AND_RECALL.ADVISORY_RECALL,
      MasterDocumentTypeConstants.FORMAT
    );
    
    try {
      const reqObj = {
        documentName: "Advisory-Recall",
        userList: selectuserData?.map((val) => ({
          userId: val?.userId,
        })),
        orgId: userData?.orgId,
        productParentId: selectedData?.parentProduct?._id,
        masterDocId: masterDocData?._id,
        productChildId: selectedData?.childProduct?._id,
        productName: `${selectedData?.parentProduct?.productName}-${selectedData?.childProduct?.productCode}`,
        module: "recall",
      };
     
      
      const uri = url?.POST_PRO_FORMAT_PRODUCT_RECALL_REGISTER;
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.meta?.success == true) {
        showToast("Product recall registered successfully!", true);
        setIsOpen(false);
        let notificationObj = {
          dataArray: [
            {
              recipients: selectuserData?.map((user) => ({
                userId: user.userId,
              })),
              msg: "Recall Notified",
              status: "Recall Notified",
              location: `/${routeConstants.ADVISORY_NOTICE_AND_RECALL}/recall-list/${new Date().getFullYear()}`,
            },
          ],
        };

        await sendNotification(notificationObj);
      }
    } catch (error) {
      console.error("ERROR TO SUBMIT ", error);
    }
  };

  return (
    <>
    <Button variant="contained" 
      onClick={()=>setResponsibileUserModal(true)}
      disabled={!checkPermission(permissionData, FormatConstants.ADVISORY_NOTICE_AND_RECALL.ADVISORY_RECALL,permissionConstants.CREATE)}
      >Recall Modal</Button>
      {/* CustomNotification Dialog */}
      {responsibileUserModal && (
        <CustomNotification
          open={responsibileUserModal}
          setOpen={setResponsibileUserModal}
          handleSubmit={handleSubmit}
          isMultipleUser={true}
        />
      )}

      {/*Product dialog */}
      {activeStep
        ? isOpen && (
            <GetProduct
              open={isOpen}
              setOpen={setIsOpen}
              setParentData={setParentData}
              handleSubmit={handleGetProduct}
              parentData={parentData}
              selectedProductData={selectedProductData}
            />
          )
        : null}
    </>
  );
};

export default withRouter(RecallModal);
