export const Constants = {
  KEY_SELECTED_ROLE: "selected_role",
  KEY_CAPA_DETAILS: "capaDetails",
  KEY_USER_DATA: "user_data",
  STANDARDNAME: "standardName",
  CHANGENOTEDATA: "changeNoteData",
  PERMISSIONDATA: "permissionData",
  DOCUMENT_NUMBERS: "documentNumbers",
  KEY_NOTIFICATION_SOUND: "notification_sound",
  KEY_NOTIFICATION_COUNT: "notification_count",
  KEY_USER_EMAIL: "user_email",
  KEY_USER_PASSWORD: "user_password",
  FIREBASE_ID: "firebase_id",
  LOCATION: "location",
  KEY_USER_ID: "user_id",
  KEY_USER_TOKEN: "user_token",
  KEY_COMPANY_DATA: "companyData",
  AGREEMENTDATA: "agreementData",
  CONFIGDATA: "configData",
  STANDARDLIST: "standardlists",
  USERLISTS: "userlists",
  KEY_HOST_NAME: "hostname",
  OUTOFRANGEFIELDS: "outOfRangeFields",
  KEY_USER_IMAGE_URL: "user_image_url",
  KEY_IS_VERIFIED: "is_verified",
  KEY_EMAIL_VERIFIED: "email_verified",
  TEMPID: "tempId",
  CONOSLE_SUCCESS: "\x1b[1m\x1b[42m\x1b[30m%s\x1b[0m",
  CONOSLE_ERROR: "\x1b[1m\x1b[41m\x1b[30m%s\x1b[0m",
  CONOSLE_REQUEST: "\x1b[1m\x1b[46m\x1b[30m%s\x1b[0m",
  SUCCESS: "success",
  ERROR: "error",
  KEY_RBAC_DOWNLOAD: "download",
  KEY_RBAC_CREATE: "create",
  KEY_RBAC_DELETE: "delete",
  KEY_RBAC_UPDATE: "update",
  KEY_RBAC_READ: "read",
  KEY_PERMISSION_DATA: "permissionData",
};

// Types
export const TypeConstants = {
  TYPE_FIND_ALL_USERS: "find-all-users",
  TYPE_FIND_TOP_MANAGEMENT: "find-top-management",
  TYPE_FIND_HEAD: "find-head",
  TYPE_FIND_ALL_USERS_BY_DEPARTMENT: "find-all-users-by-department",
  TYPE_FIND_ALL_USERS_BY_DESIGNATION: "find-all-users-by-designation",
  TYPE_FIND_ALL_DEPARTMENTS: "find-all-departments",
  TYPE_FIND_ALL_DESIGNATIONS: "find-all-designations",
};

// Department Constant
export const DepartmentConstants = {
  HR_DEPARTMENT: "HR Department",
  PURCHASE_DEPARTMENT: "Purchase Department",
  STORE_DEPARTMENT: "Store Department",
  DESIGN_AND_DEVELOPMENT_DEPARTMENT: "Design and Development Department",
  PRODUCTION_DEPARTMENT: "Production Department",
  QUALITY_CONTROL_DEPARTMENT: "Quality Control Department",
  MAINTENANCE_DEPARTMENT: "Maintenance Department",
  QUALITY_ASSURANCE_DEPARTMENT: "Quality Assurance Department",
  REGULATORY_AFFAIRS_DEPARTMENT: "Regulatory Affairs Department",
  SALES_AND_MARKETING_DEPARTMENT: "Sales and Marketing Department",
  DISPATCH_DEPARTMENT: "Dispatch Department",
  TOP_MANAGEMENT: "Top-Management",
};

// Designation Constant
export const DesignationConstants = {
  HR_HEAD: "HR Head",
  HR_MANAGER: "HR Manager",
  HR_EXECUTIVE: "HR Executive",
  PURCHASE_HEAD: "Purchase Head",
  PURCHASE_MANAGER: "Purchase Manager",
  PURCHASE_EXECUTIVE: "Purchase Executive",
  STORE_HEAD: "Store Head",
  STORE_MANAGER: "Store Manager",
  STORE_EXECUTIVE: "Store Executive",
  DESIGN_AND_DEVELOPMENT_HEAD: "Design and Development Head",
  DESIGN_AND_DEVELOPMENT_MANAGER: "Design and Development Manager",
  DESIGN_AND_DEVELOPMENT_EXECUTIVE: "Design and Development Executive",
  PRODUCTION_HEAD: "Production Head",
  PRODUCTION_MANAGER: "Production Manager",
  PRODUCTION_EXECUTIVE: "Production Executive",
  QUALITY_CONTROL_HEAD: "Quality Control Head",
  QUALITY_CONTROL_MANAGER: "Quality Control Manager",
  QUALITY_CONTROL_EXECUTIVE: "Quality Control Executive",
  MAINTANANCE_HEAD: "Maintanance Head",
  MAINTANANCE_MANAGER: "Maintanance Manager",
  MAINTANANCE_EXECUTIVE: "Maintanance Executive",
  QUALITY_ASSURANCE_HEAD: "Quality Assurance Head",
  QUALITY_ASSURANCE_MANAGER: "Quality Assurance Manager",
  QUALITY_ASSURANCE_EXECUTIVE: "Quality Assurance Executive",
  REGULATORY_AFFAIRS_HEAD: "Regulatory Affairs Head",
  REGULATORY_AFFAIRS_MANAGER: "Regulatory Affairs Manager",
  REGULATORY_AFFAIRS_EXECUTIVE: "Regulatory Affairs Executive",
  SALES_AND_MARKETING_HEAD: "Sales and Marketing Head",
  SALES_AND_MARKETING_MANAGER: "Sales and Marketing Manager",
  SALES_AND_MARKETING_EXECUTIVE: "Sales and Marketing Executive",
  DISPATCH_HEAD: "Dispatch Head",
  DISPATCH_MANAGER: "Dispatch Manager",
  DISPATCH_EXECUTIVE: "Dispatch Executive",
  DISPATCH_EXECUTIVE: "Dispatch Executive",
  MANAGING_DIRECTOR: "Managing Director",
  DIRECTOR: "Director",
  MR: "MR",
};

// Master Document Type Constant
export const MasterDocumentTypeConstants = {
  QUALITYMANUAL: "qualityManual",
  PROCEDURE: "procedure",
  FORMAT: "format",
  RECORD: "record",
};

//Formats
export const FormatConstants = {
  INSTALLATION: {
    CHECKLIST_INSTALLATION_INTERNAL: "Checklist For Installation Internal",
    INSTALLATION_REPORT_INTERNAL: "Installation Report Internal",
    CHECKLIST_INSTALLATION_EXTERNAL: "Checklist For Installation External",
    INSTALLATION_REPORT_EXTERNAL: "Installation Report External",
  },
  CAPA: {
    CORRECTIVE_ACTION: "Corrective Action",
    PREVENTICE_ACTION: "Preventive Action",
  },
  COR: {
    MASTERLIST_OF_QUALITY_RECORDS: "Master List Of Quality Records",
  },
  CHANGE_NOTE: {
    CHANGE_NOTE: "Change Note",
  },
  COD: {
    LIST_OF_EXTERNAL_ORIGIN: "List Of External Origin",
    MASTERLIST_OF_DOCUMENTS: "Master List Of Documents",
    CHANGE_NOTE: "Change Note",
  },
  PROCESS_VALIDATION: {
    MASTER_VALIDATION_PLAN: "Master Validation Plan",
    VALIDATION_PROTOCOL_REPORT: "Validation Protocol & Report",
  },
  FEEDBACK_FORMAT: {
    FEEDBACK: "Feedback",
  },
  INTERNAL_COMMUNICATION: {
    INTERNAL_CIRCULAR: "Internal Circular",
  },
  IQA: {
    INTERNAL_QUALITY_AUDIT_SCHEDULED: "Internal Quality Audit Schedule",
    LIST_OF_AUDITORS: "List Of Auditors",
    INTERNAL_AUDIT_PLAN: "Internal Audit Plan",
    INTERNAL_AUDIT_REPORT: "Internal Quality Audit Report",
    CORRECTIVE_ACTION_REPORT: "Corrective Action Report",
  },
  COMPLAINT: {
    CUSTOMER_COMPLAINT: "Customer Complaints",
  },
  CUSTOMERPROPERTY: {
    APPROVAL_OF_CUSTOMER_PROPERTY: "Approval Of Customer Property",
  },
  CAT: {
    TRAINING_CALENDAR: "Training Calendar",
    COMPETENCY_MATRIX: "Competency Matrix",
    SAVED_REQUISITION_MANPOWER: "Saved Requisition Manpower",
    REQUISITION_FOR_MANPOWER: "Requisition For Manpower",
    INDUCTION_TRAINING_RECORD: "Induction Training Record",
    TRAINING_NEED_ASSESSMENT: "Training Need Assessment",
    TRAINING_ATTENDANCE_SHEET: "Training Attendance Sheet",
    TRAINING_NEED_ASSESSMENT_FORM: "Training Need Assesment Form",
    TRAINING_ATTENDANCE: "Training Attendance",
    TRAINING_EVALUATION: "Training Evaluation",
    TRAINING_EVALUATION_FILL: "Training Evaluation Fill",
    TRAINING_EVALUATION_RECORD: "Training Evaluation Record",
    MAN_POWER_LIST_WITH_SPECIMENT_SIGNATURE:
      "Man-Power List With Specimen Signature",
  },
  WORKENVIRONMENT: {
    CLEANING_REGISTER: "Cleaning Register",
    TEMPERATURE_HUMIDITY_REGISTER: "Temperature And Humidity Monitoring Record",
    FUMIGATION_RECORD: "Fumigation Record",
    PERSONAL_HYGIENE_RECORD: "Personnel Hygiene Record",
    APRON_SLIPPER_CLEANING_RECORD: "Apron and Slipper Cleaning Record",
    PEST_CONTROL_RECORD: "Pest Control Record",
    ESD_CONTROL_LOG: "ESD Control Log",
  },
  CUSTOMERSERVICE: {
    CUSTOMER_SERVICING_FORM: "Service Records",
    CUSTOMER_SERVICING_DETAILS: "Customer Servicing Details",
    CUSTOMER_SERVICING_FEEDBACK: "Servicing Feedback Form",
  },
  CRPD: {
    ORDER_FORM: "Order Form",
    ORDER_REVIEW_FORM: "Order Review Form",
    SALES_INFORMATION: "Sales Information",
    PRODUCT_HISTORY: "Product History",
    AGENT_HISTORY: "Agent History",
    DISTRIBUTION_RECORD: "Distribution Record",
    LIST_OF_DISTRIBUTOR: "List Of Distributor",
  },
  MRM: {
    //NEED TO DISCUSS
    MRM_PLAN: "Mrm Plan",
    MRM_CIRCULAR: "MRM Circular",
    MINUTES_OF_MEETING: "Minutes Of Meeting",
  },
  PURCHASE: {
    //NEED TO DISCUSS
    PURCHASE_ORDER: "Purchase Order",
    SUBCONTRACTOR_AGREEMENT: "Subcontractor Agreement",
    RAW_MATERIAL_STOCK_REGISTER: "Raw Material Stock Register",
    INCOMING_INSPECTION_REPORT: "Incoming Inspection Report",
    SUPPLIER_EVALUATION_FORM: "Supplier Evaluation Form",
    SUPPLIER_AGREEMENT: "Supplier Agreement",
    REQUISITION_SLIP: "Requisition Slip",
    ISSUE_SLIP: "Issue Slip",
    APPROVED_SUPPLIER_LIST: "Approved Supplier List",
    SUPPLIER_RE_EVALUATION_FORM: "Supplier Re-evaluation Form",
    AGREEMENT_WITH_LABORATORY: "Agreement With Laboratry",
    TEST_REQUEST_NOTE: "Test Request Note",
    SUPPLIER_FORM: "Supplier/Service Provider Selection",
  },
  MAMOFEQUIPMENT: {
    MASTERLIST_OF_EQUIPMENT: "Master List Of Equipment",
    INTERNAL_CALIBRATION_RECORD: "Internal Calibration Record",
    EXTERNAL_CALIBRATION_RECORD: "External Calibration Record",
  },
  PRODUCTION: {
    RAW_MATERIAL_REQUISITION_SLIP: "Raw Material Requisition Slip",
    PRODUCTION_PLANNING: "Production Planning",
    BATCH_MANUFACTURING_RECORD: "Batch Manufacturing Record",
    STOCK_REGISTER: "Stock Register",
  },
  MAMOFPRODUCT: {
    INCOMING_INSPECTION_PLAN: "Incoming Inspection Plan",
    INPROCESS_INSPECTION_PLAN: "In Process Inspection Plan",
    FINAL_INSPECTION_Plan: "Final Inspection Plan",
    INCOMING_INSPECTION_REPORT: "Incoming Inspection Report",
    INPROCESS_INSPECTION_REPORT: "Inprocess Inspection Report",
    FINAL_INSPECTION_REPORT: "Final Inspection Report",
    INDEPENDENT_INPROCESS_REPORT: "Independent In Process Inspection Report",
    SAMPLING_PLAN: "Sampling Plan",
  },
  NON_CONFORMING_OF_PRODUCT: {
    REJECTION_RECORD: "Rejection Record",
    SCRAP_RECORD: "Scrap Record",
    REWORK_RECORD: "Rework Record",
    RETURN_RECORD: "Return Record",
    CONCESSION_RECORD: "Concession Record",
    NON_CONFORMING_OF_PRODUCT: "Non-Conforming Product Report",
  },
  PREVENTIVE_MAINTAINANCE: {
    MASTER_LIST_OF_MACHINE: "List of Machines",
    MACHINE_BREAKDOWN_REPORT: "Machine Breakdown record",
    MAINTAINANCE_PLAN: "Preventive Maintenance Plan",
    PREVENTIVE_MAINTAINANCE_REPORT: "Maintenance Report",
  },
  ADVISORY_NOTICE_AND_RECALL: {
    ADVISORY_NOTICE: "Advisory Notice",
    ADVISORY_RECALL: "Product Recall Register",
    RETURNED_GOODS_RECORD: "Returned Goods Record",
  },
  FINISHED_REGISTER_PRODUCT: {
    FINISHED_PRODUCT_REGISTER: "Finished Product Register",
  },
  WORK_ENVIRONMENT: {
    CLEANING_REGISTER: "Cleaning Register",
    TEMPERATURE_AND_HUMIDITY_MONITORING_RECORD:
      "Temperature And Humidity Monitoring Record",
    FUMIGATION_RECORD: "Fumigation Record",
    PERSONNAL_HYGIENE_RECORD: "Personnel Hygiene Record",
    APRON_SLIPPER_CLEANING_RECORD: "Apron And Slipper Cleaning Record",
    PEST_CONTROL_RECORD: "Pest Control Record",
    ESD_CONTROL_LOG: "ESD Control Log",
  },
  CONTROL_OF_NON_CONFIRMING_PRODUCT: {
    REGISTER: "Non-Conforming Product Register",
    REPORT: "Non-Conforming Product Report",
    RETURN_RECORD: "Return Record",
    CONCESSION_RECORD: "Concession Record",
    SCRAP_RECORD: "Scrap Record",
    REJECTION_RECORD: "Rejection Record",
    REWORK_RECORD: "Rework Record",
  },
};

export const routeConstants = {
  SAMLING_PLAN: "sampling-plan",
  CAT: "competence-awareness-and-training",
  FEEDBACK: "feedback",
  CHANGE_NOTE: "change-note",
  INTERNAL_COMMUNICATION: "internal-communication",
  CONTROL_OF_DOCUMENT: "control-of-document",
  CONTROL_OF_RECORDS: "control-of-records",
  CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION:
    "customer-related-process-and-distribution",
  MANAGMENT_REVIEW_MEETING: "management-review-meeting",
  INTERNAL_QUALITY_AUDIT: "internal-quality-audit",
  ADVISORY_NOTICE_AND_RECALL: "advisory-notice-and-recall",
  INSTALLATION: "installation",
  PROCESS_VALIDATIONS: "process-validation",
  CORRECTIVE_ACTION_PREVENTIVE_ACTION: "corrective-action-preventive-action",
  CUSTOMER_SERVICE: "customer-service",
  CUSTOMER_COMPLAINTS: "customer-complaints",
  CONTROL_OF_CUSTOMER_PROPERTY: "control-of-customer-property",
  PREVENTIVE_MAINTAINCE: "preventive-maintenance",
  COMMUNICATION_WITH_REGULATORY_BODIES: "communication-with-regulatory-bodies",
  CONTROL_OF_NON_CONFIRMING: "control-of-non-confirming",
  MAM_OF_EQUIPMENT: "monitoring-and-measurement-of-equipment",
  MAM_OF_PRODUCT: "monitoring-and-measuring-of-product",
  MAM_OF_PRODUCT_NEW: "monitoring-and-measuring-of-product-new",
  WORK_ENVIRONMENT: "work-environment",
  CAPA: "capa",
  COD: "control-of-document",
  COR: "control-of-record",
  MRM: "mrm",
  PRODUCTION: "production",
  PRODUCT: "product",
  FINISHED_PRODUCT_REGISTER: "finished-product-register",
  IQA: "iqa",
  PURCHASE: "purchase",
};

export const commonRoutesConstants = {
  YEAR_LIST: "year-list",
};

export const documentTypesConstants = {
  DRAFT: "Draft",
  // ORIGINAL: "Original",
  CURRENT: "Current",
  ABSOULTE: "Absolute",
};

export const permissionConstants = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
  DOWNLOAD: "download",
  MAKE_DECISION: "makeDecision",
  APPROVED_BY_ESIGN: "approvedByESign",
  REVIEWED_BY_ESIGN: "reviewedByESign",
};
