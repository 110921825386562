import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Constants,
  DesignationConstants,
  DepartmentConstants,
  TypeConstants,
  MasterDocumentTypeConstants,
  FormatConstants,
  routeConstants,
  documentTypesConstants,
  permissionConstants
} from "services/keyConstants";
import { getDocumentTitle, getItem, handleGetPermission } from "services/utils";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { useTheme } from "@mui/material";
import service from "services/constant";
import {
  ThemeMode,
  DRAWER_WIDTH,
  MenuOrientation,
  MINI_DRAWER_WIDTH,
  APP_DEFAULT_PATH,
  ThemeDirection,
} from "config";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";

const withRouter = (Component) => (props) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const userData = getItem(Constants.KEY_USER_DATA);
  const companyData = getItem(Constants.KEY_COMPANY_DATA);
  const userRole = getItem(Constants.KEY_SELECTED_ROLE);
  const documentNumbers = getItem(Constants.DOCUMENT_NUMBERS);
  const selectedRole = getItem(Constants.KEY_SELECTED_ROLE);
  const changeNoteData = getItem(Constants.CHANGENOTEDATA);
  const userRoleData = getItem(Constants.KEY_SELECTED_ROLE);
  const configData = getItem(Constants.CONFIGDATA);
  const { onChangeDownloadState } = useContext(ConfigContext);
  const getPageTitle = (title) => {
    document.title = getDocumentTitle(title);
  };

  return (
    <Component
      {...props}
      theme={theme}
      router={{ location, navigate, params }}
      userData={userData}
      companyData={companyData}
      selectedRole={selectedRole}
      getTitle={getPageTitle}
      configData={configData}
      ApiServices={ApiServices}
      commonUrl={url}
      userRole={userRole}
      userRoleData={userRoleData}
      service={service}
      Constants={Constants}
      TypeConstants={TypeConstants}
      MasterDocumentTypeConstants={MasterDocumentTypeConstants}
      DepartmentConstants={DepartmentConstants}
      DesignationConstants={DesignationConstants}
      FormatConstants={FormatConstants}
      routeConstants={routeConstants}
      handleGetPermission={handleGetPermission}
      documentNumbers={documentNumbers}
      onChangeDownloadState={onChangeDownloadState}
      changeNoteData={changeNoteData}
      documentTypesConstants={documentTypesConstants}
      permissionConstants={permissionConstants}
      config={{
        ThemeMode,
        DRAWER_WIDTH,
        MenuOrientation,
        MINI_DRAWER_WIDTH,
        APP_DEFAULT_PATH,
        ThemeDirection,
      }}
    />
  );
};

export default withRouter;
