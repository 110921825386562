import {
    Box,
    Button,
    Card,
    Grid,
    Stack,
    Typography,
    Dialog,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    FormLabel,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@mui/material";
import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import Error404 from "components/reusables/404";
import withRouter from "components/withRouter";
import CustomFolderSkeleton from "components/CustomFolderSkeleton";
import CustomDialog from "./CustomDialog";
import {
    checkPermission,
    getPermssionDataByName,
    showToast,
} from "services/utils";
import CustomAccordion from "components/CustomAccordian";
import { permissionConstants } from "services/keyConstants";
const CommonYearList = (props) => {
    const {
        ApiServices,
        uri,
        title,
        navigateToFolder,
        isCreate = false,
        createFormatConstantsPermission = "",
        createPermissionConstants = "",
        userData,
        visibleButtonCondition = false,
        query,
        createRoute,
        isExtraButtons = false,
        extraButtonChildren = [],
        customDialogProps = {},
        isRenderComponent,
        getByType = () => { },
        //for year and month
        typeOfYearList = "Year",
        navigateToFolderForMonth,
        paramName,
        isParam = false,
        permissionFormatConstants = [],
    } = props,
        { navigate, params } = props?.router;
    const { type } = params ?? {};
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [usersData, setUsersData] = useState([]);
    const [activeDialog, setActiveDialog] = useState(null); // Tracks the currently active dialog
    const [dialogState, setDialogState] = useState({}); // Dialog-specific state
    const [openDialog, setOpenDialog] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [permissionData, setPermissionData] = useState([]);

    const handleOpenFolder = (row) => {
        if (getByType(type)?.navigateToFolder) {
            navigate(`${getByType(type)?.navigateToFolder}`);
        } else if (isParam) {
            navigate(`${navigateToFolder}${row?.year}/${row?.[`${paramName}`]}`);
        } else {
            navigate(`${navigateToFolder}${row?.year}`);
        }
    };

    const getData = async () => {
        try {
            setIsLoading(true);
            let tempUrl;
            if (getByType) {
                tempUrl = getByType(type)?.uri;
            }
            let result = await ApiServices.callServiceGet(tempUrl ?? uri);
            if (result?.meta?.success) {
                setData(result?.data);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData();
        if (permissionFormatConstants?.length > 0 || createFormatConstantsPermission?.length > 0) {
            getPermissionData();
        }
        if (visibleButtonCondition) {
            getUsersData(query);
        }
    }, []);

    const getUsersData = async (uri) => {
        const result = await getUserDataByDesignation(uri, false);
        setUsersData(result ?? []);
    };

    const getPermissionData = async () => {
        let result = await getPermssionDataByName(
            permissionFormatConstants?.length > 0
                ? permissionFormatConstants
                : createFormatConstantsPermission
        );
        console.log(result, "result=====>");
        setPermissionData(result ?? []);
    };

    const handleNavigate = async () => {
        if (createRoute?.modelName) {
            window.dispatchEvent(new Event(createRoute?.modelName));
        } else if (createRoute?.path) {
            navigate(createRoute?.path);
        } else if (createRoute?.callExtraApi) {
            const result = await createRoute?.callExtraApi();
            if (result?.meta?.success) {
                getData();
            }
        } else if (getByType && getByType(type)?.createRoute) {
            let tempUrl = getByType(type)?.createRoute;
            navigate(tempUrl?.path);
        }
    };

    const handleNavigateMonth = (value, record) => {
        const newUri = navigateToFolderForMonth
            ?.replace("=year", `=${value?.year}`)
            ?.replace("=month", `=${record?.month}`);
        navigate(newUri);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDialogOpen = () => {
        if (customDialogProps?.initialState) {
            const initialState = customDialogProps.initialState.reduce(
                (acc, field) => {
                    acc[field.name] =
                        field.type === "checkbox"
                            ? false
                            : field.type === "radio"
                                ? false
                                : ""; // Set initial value
                    return acc;
                },
                {}
            );
            setDialogState(initialState);
        }
        setActiveDialog(customDialogProps);
        setOpenDialog(true);
    };

    const handleNavigateExtra = (path) => {
        navigate(path);
    };

    const handleDialogClose = () => {
        setActiveDialog(null);
        setDialogState({});
        setOpenDialog(false);
    };

    const handleDialogAction = async (actionType, payload) => {
        if (!activeDialog) return;

        try {
            const result =
                actionType === "PUT"
                    ? await ApiServices.callServicePut(activeDialog.apiUri, payload)
                    : await ApiServices.callServicePost(activeDialog.apiUri, payload);

            if (result?.meta?.success) {
                getData(); // Refresh data
                handleDialogClose();
            }
        } catch (error) {
            console.error("Dialog Action Error:", error);
        }
    };

    const renderField = (field) => {
        switch (field?.type) {
            case "radio":
                return (
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={dialogState[field?.name]}
                            onChange={(e) => {
                                setDialogState((prev) => ({
                                    ...prev,
                                    [field?.name]: e?.target?.value,
                                }));
                            }}
                        >
                            <FormControlLabel
                                value={field?.value}
                                control={<Radio />}
                                label={field?.label}
                            />
                        </RadioGroup>
                    </FormControl>
                );
            case "text":
                return (
                    <TextField
                        fullWidth
                        label={field.label}
                        value={dialogState[field.name]}
                        onChange={(e) =>
                            setDialogState((prev) => ({
                                ...prev,
                                [field.name]: e.target.value,
                            }))
                        }
                    />
                );
            case "number":
                return (
                    <TextField
                        fullWidth
                        type="number"
                        label={field.label}
                        value={dialogState[field.name]}
                        onChange={(e) =>
                            setDialogState((prev) => ({
                                ...prev,
                                [field.name]: e.target.value,
                            }))
                        }
                    />
                );
            case "select":
                return (
                    <Select
                        fullWidth
                        value={dialogState[field.name]}
                        onChange={(e) =>
                            setDialogState((prev) => ({
                                ...prev,
                                [field.name]: e.target.value,
                            }))
                        }
                    >
                        {field?.options?.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option?.label}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case "checkbox":
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dialogState[field.name] || false}
                                onChange={(e) =>
                                    setDialogState((prev) => ({
                                        ...prev,
                                        [field.name]: e.target.checked,
                                    }))
                                }
                            />
                        }
                        label={field.label}
                    />
                );

            default:
                return null;
        }
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date?.setMonth(monthNumber - 1);
        return date?.toLocaleString("default", { month: "long" }) || "";
    };

    return (
        <>
            <MainCard
                title={`${title} Year List`}
                secondary={
                    <Stack sx={{ display: "flex", flexDirection: "row" }} gap={1}>
                        {isCreate && (
                            <Button
                                sx={{
                                    display: visibleButtonCondition
                                        ? usersData?.some((item) => item?.userId === userData?._id)
                                            ? "block"
                                            : "none"
                                        : "block",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleNavigate}
                                disabled={createFormatConstantsPermission?.length > 0 ? !checkPermission(permissionData, createFormatConstantsPermission, createPermissionConstants?.length > 0 ? createPermissionConstants : permissionConstants.CREATE) : false}
                            >
                                Create
                            </Button>
                        )}
                        {isExtraButtons && (
                            <Stack direction="row" gap={1}>
                                {extraButtonChildren?.map((button, index) => (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNavigateExtra(button?.path)}
                                        disabled={
                                            button?.disableOnPermission
                                                ? !checkPermission(
                                                    permissionData,
                                                    button?.formatConstants,
                                                    button?.permissionConstants ??
                                                    permissionConstants?.CREATE
                                                )
                                                : false
                                        }
                                    >
                                        {button?.buttonTitle}
                                    </Button>
                                ))}
                            </Stack>
                        )}
                        {customDialogProps?.length && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDialogOpen}
                            >
                                {customDialogProps?.buttonName}
                            </Button>
                        )}
                        {isRenderComponent && React.createElement(isRenderComponent)}
                    </Stack>
                }
            >
                {isLoading ? (
                    <CustomFolderSkeleton length={12} />
                ) : data.length === 0 ? (
                    <Error404
                        headingMain={`Oops, looks like there are no ${title} created yet!`}
                        subHeading={`Please fill ${title}`}
                    />
                ) : (
                    <Grid container spacing={2}>
                        {typeOfYearList === "Year"
                            ? data?.map((row, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                                    <Card
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Box
                                            onClick={() => handleOpenFolder(row)}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                textAlign: "center",
                                                borderRadius: 2,
                                                padding: 2,
                                                height: "100%",
                                                justifyContent: "center",
                                                "&:hover": {
                                                    transform: "scale(1.02)",
                                                    transition: "all 0.1s ease-in-out",
                                                },
                                            }}
                                        >
                                            <FolderIcon sx={{ fontSize: 50, color: "#5c8ee0" }} />
                                            <Typography>{row?.year}</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))
                            : typeOfYearList === "YearWithMonth"
                                ? data?.map((value, index) => {
                                    return (
                                        <Grid item xs={12}>
                                            <CustomAccordion
                                                key={index}
                                                expanded={expanded === index}
                                                onChange={handleAccordionChange(index)}
                                                summary={
                                                    <Stack>
                                                        <Typography variant="h6" component="div">
                                                            {value?.year}
                                                        </Typography>
                                                    </Stack>
                                                }
                                                details={
                                                    <>
                                                        <Grid container spacing={2}>
                                                            {value?.records?.map((record, recordIndex) => {
                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={6}
                                                                        md={4}
                                                                        lg={3}
                                                                        xl={2}
                                                                        key={recordIndex}
                                                                    >
                                                                        <Card
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleNavigateMonth(value, record)
                                                                            }
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    alignItems: "center",
                                                                                    textAlign: "center",
                                                                                    borderRadius: 2,
                                                                                    padding: 2,
                                                                                    height: "100%",
                                                                                    justifyContent: "center",
                                                                                    cursor: "pointer",
                                                                                    "&:hover": {
                                                                                        transform: "scale3d(1.02, 1.02, 1)",
                                                                                        transition: "all .10s ease-in-out",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <FolderIcon
                                                                                    sx={{
                                                                                        fontSize: 81,
                                                                                        color: "#5c8ee0",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                                <Typography>
                                                                                    {getMonthName(record?.month)}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Card>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </>
                                                }
                                            />
                                        </Grid>
                                    );
                                })
                                : null}
                    </Grid>
                )}
            </MainCard>

            <CustomDialog open={!!openDialog} onClose={handleDialogClose}>
                <DialogTitle>{activeDialog?.title}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {activeDialog?.initialState?.map((field, index) => (
                            <Box key={index}>{renderField(field)}</Box>
                        ))}
                    </Stack>
                    <DialogActions>
                        <Button
                            onClick={handleDialogClose}
                            color="error"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() =>
                                handleDialogAction(activeDialog?.actionType, dialogState)
                            }
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </DialogContent>
            </CustomDialog>
        </>
    );
};

export default withRouter(CommonYearList);
