const baseUrlOld = "/permissions";
const baseUrl = "/permission";

export const GET_ALL_PERMISSION_DATA = `${baseUrlOld}/all-permissions/`;
export const GET_BY_ORGANOGRAM = `${baseUrlOld}/by-organogram/`;
export const UPDATE_ALL_PERMISSION_DATA = `${baseUrlOld}/`;
export const GET_NEW_ASSIGN_MODULE_DATA = `${baseUrlOld}/all-permissions/list`;
export const CREATE_NEW_MODULE = `${baseUrlOld}/data`;

export const GET_ALL_DATA_BY_MODULE_TYPE = `${baseUrlOld}/list`;

export const GET_PERMISSSION = `/${baseUrl}/get`;
export const GET_PERMISSSION_USER = `/${baseUrl}/get-user`;
export const GET_PERMISSSION_ADMIN = `/${baseUrl}/get-admin`;

export const ADD_EDIT_PERMISSSION = `/${baseUrl}`;
export const ADD_EDIT_ADMIN_PERMISSSION = `/${baseUrl}/admin`;
export const GET_PERMISSSION_BY_NAME = `/${baseUrl}/fetch`;
export const GET_DOCS_WITH_PERMISSIONS = `/${baseUrl}/get-docs`;
