import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getUserDataByDesignation } from "services/organogramServices";
import CustomDialog from "components/reusables/CustomDialog";
import EmailSelector from "components/EmailSelector";
import * as url from "./utils/UrlHelper";
import withRouter from "components/withRouter";
import { CustomHooks } from "hooks/CustomHooks";
import {
  checkPermission,
  getPermssionDataByName,
  sendNotification,
  showToast,
} from "services/utils";

const CreateInstallationFormat = (props) => {
  const { ApiServices, userData, getData, TypeConstants, FormatConstants } =
    props;
  const { procedureId } = CustomHooks.useUrlParams();
  const [selectedModal, setSelectedModal] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const installationReportOptions = ["Internal", "External"];
  const [installationReportResponce, setInstallationReportResponce] =
    useState(null);
  const [installationReportData, setInstallationReportData] = useState({
    type: "Internal",
    internalUser: "",
    subType: "Generate-Link",
    selectedOption: "copyLink",
    userEmails: [],
  });
  const [validationErrors, setValidationErrors] = useState({
    type: "",
    internalUser: "",
    userEmails: "",
  });
  const [allUsers, setAllUsers] = useState([]);
  const [permissionData, setPermissionData] = useState([]);

  const getDeparmentUsers = async () => {
    const departmentData = await getUserDataByDesignation(
      [
        {
          type: TypeConstants.TYPE_FIND_ALL_DEPARTMENTS,
        },
      ],
      false
    );
    const result = departmentData?.filter((item) =>
      item?.designation?.toLowerCase()?.includes("installation")
    );
    if (result?.length > 0) {
      const installationData = await getUserDataByDesignation(
        [
          {
            type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DEPARTMENT,
            department: result?.map((item) => item?.designation),
          },
        ],
        false
      );

      setShowButton(
        installationData.some((item) => {
          return item?.userId === userData?._id;
        })
      );
    }
  };

  const closeModal = () => {
    setSelectedModal(null);
  };

  const handleEmailsChangeInstallation = (emails) => {
    const limitedEmails = emails.slice(0, 1);

    setInstallationReportData((prev) => ({
      ...prev,
      userEmails: limitedEmails,
    }));
    setEmailError(limitedEmails.length === 0);
  };

  const getAllRoles = async () => {
    try {
      const allUsersList = await getUserDataByDesignation(
        [
          {
            type: TypeConstants.TYPE_FIND_ALL_USERS,
          },
        ],
        false
      );

      const allUserNames = allUsersList?.map((item) => ({
        userName: item?.userName,
        userId: item?.userId,
      }));

      const uniqueUserNames = allUserNames?.filter(
        (item, index, self) =>
          item.userId &&
          self.findIndex((i) => i.userId === item.userId) === index
      );

      setAllUsers(uniqueUserNames);
    } catch (error) {
      console.error("GET_ALL_ROLES_API ERROR", error);
    }
  };

  const handleInstallationReportChange = (e) => {
    const { name, value } = e.target;

    setInstallationReportData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setEmailError(false);
  };

  const handleInstallationReportModal = () => {
    setSelectedModal((prevValue) =>
      prevValue === "installationReport" ? "" : "installationReport"
    );
    setInstallationReportData({
      type: "Internal",
      internalUser: "",
      subType: "Generate-Link",
      selectedOption: "copyLink",
      userEmails: [],
    });
    setInstallationReportResponce(null);
  };
  const validateForm = () => {
    const errors = {};
    if (installationReportData?.type === "Internal") {
      if (!installationReportData?.internalUser) {
        errors.internalUser = "Internal user is required";
      }
    }
    if (installationReportData?.type === "External") {
      if (installationReportData?.userEmails?.length === 0) {
        errors.userEmails =
          "At least one email is required for external reports";
      }
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInstallationSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      let formatObj = {
        orgId: userData?.orgId,
        procudureId: procedureId,
        documentName: "Installation",
        document: {
          formatNumber: "",
          revisionNumber: "v001",
          revisionDate: "",
          data: [],
          isSubmitted: false,
        },
        date: new Date(),
      };
      if (installationReportData?.type === "External") {
        if (installationReportData?.userEmails?.length === 0) {
          setEmailError(true);
          return;
        }
        let domainLink =
          window.location.origin + `/installation-external-format`;
        let reqObj = {
          ...formatObj,
          type: "External",
          document: {
            ...formatObj.document,
            externalUser: installationReportData?.userEmails,
            link: domainLink,
          },
          prefixType: "FormatPrefixes",
        };
        const uri = url.SENT_EMAIL;
        const response = await ApiServices.callServicePostWithBodyData(
          uri,
          reqObj
        );
        if (response.meta.success) {
          closeModal();
          getData();
          showToast(response);
        }
      }
      if (installationReportData?.type === "Internal") {
        let reqObj = {
          ...formatObj,
          type: "Internal",
          document: {
            ...formatObj.document,
            internalUser: {
              userId: installationReportData?.internalUser?.userId,
              userName: installationReportData?.internalUser?.userName,
            },
          },
        };

        let uri = url.INSTALLATION_CREATE_FORMATS;
        const result = await ApiServices.callServicePostWithBodyData(
          uri,
          reqObj
        );
        if (result.meta.success) {
          getData();
          closeModal();
          showToast(result);
          const reqObjNotification = {
            dataArray: [
              {
                recipients: [reqObj.document.internalUser],
                msg: "Installatation report submitted",
                status: "Installation report Submitted",
              },
            ],
          };
          sendNotification(reqObjNotification);
        } else {
          showToast(result);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPermissionData = async () => {
    let result = await getPermssionDataByName(
      FormatConstants.INSTALLATION.CHECKLIST_INSTALLATION_INTERNAL
    );
    setPermissionData(result ?? []);
  };

  useEffect(() => {
    getPermissionData();
    getDeparmentUsers();
    getAllRoles();
  }, []);
  return (
    <>
      {showButton && (
        <Button
          variant="contained"
          onClick={handleInstallationReportModal}
          disabled={
            !checkPermission(
              permissionData,
              FormatConstants.INSTALLATION.CHECKLIST_INSTALLATION_INTERNAL
            )
          }
        >
          Create
        </Button>
      )}
      {selectedModal === "installationReport" && (
        <CustomDialog open={selectedModal}>
          <DialogTitle>Installation Report</DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Installation Type
                      </InputLabel>
                      <Select
                        placeholder="Select Standard"
                        name="type"
                        value={installationReportData?.type}
                        onChange={(e) => {
                          handleInstallationReportChange(e);
                        }}
                      >
                        {installationReportOptions?.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                        {validationErrors?.type && (
                          <FormHelperText error>
                            {validationErrors?.type}
                          </FormHelperText>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  {installationReportData?.type === "Internal" && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="internalUser">Internal User</InputLabel>
                        <Select
                          labelId="internalUser"
                          name="internalUser"
                          value={installationReportData?.internalUser || ""}
                          onChange={(e) => handleInstallationReportChange(e)}
                          fullWidth
                          displayEmpty
                          renderValue={(selected) => selected.userName || ""}
                        >
                          {allUsers?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={{
                                userId: item?.userId,
                                userName: item?.userName,
                              }}
                            >
                              {item?.userName}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationErrors?.internalUser && (
                          <FormHelperText error>
                            {validationErrors?.internalUser}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {installationReportData?.type === "External" && (
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <EmailSelector
                          emails={installationReportData?.userEmails}
                          setEmails={handleEmailsChangeInstallation}
                        />
                        {emailError && (
                          <span
                            style={{
                              color: "red",
                              marginTop: "0.5rem",
                              display: "block",
                            }}
                          >
                            Email is required.
                          </span>
                        )}
                        {validationErrors?.userEmails && (
                          <FormHelperText error>
                            {validationErrors?.userEmails}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button color="error" variant="contained" onClick={closeModal}>
                Cancel
              </Button>
              {installationReportResponce === null && (
                <Button
                  color="success"
                  variant="contained"
                  type="submit"
                  onClick={() => handleInstallationSubmit()}
                >
                  Submit
                </Button>
              )}
            </Stack>
          </DialogActions>
        </CustomDialog>
      )}
    </>
  );
};

export default withRouter(CreateInstallationFormat);
