import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "../utils/urlHelpers";

const Create = Loadable(lazy(() => import("../Create")));
const List = Loadable(lazy(() => import("../List")));
const PestControl = Loadable(lazy(() => import("../PestControl")));
const EsdControl = Loadable(lazy(() => import("../EsdControl")));
const ApproneAndSlipperCleaningRecord = Loadable(lazy(() => import("../ApproneAndSlipperCleaningRecord")));
const TemperatureAndHumidityMonitoringRecord = Loadable(lazy(() => import("../TemperatureAndHumidityMonitoringRecord")));
const FumigationRecord = Loadable(lazy(() => import("../FumigationRecord")));
const PersonnelHygiene = Loadable(lazy(() => import("../PersonnelHygiene")));
const CleaningRegister = Loadable(lazy(() => import("../CleaningRegister")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
const WERoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.WORK_ENVIRONMENT, null, [
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.WORK_ENVIRONMENT}
        isCreate={true}
        createRoute={{
          path: `/${routeConstants.WORK_ENVIRONMENT}/create`,
        }}
        navigateToFolder={`/${routeConstants.WORK_ENVIRONMENT}/list/`}
      />),
      createRoute("create", <Create />),
      createRoute("list/:year?", <List />),
      createRoute("list/:year?/:masterDocId?/:month?", <List />),
      createRoute("pest-control/:id?", <PestControl />),
      createRoute("esd-control/:id?", <EsdControl />),
      createRoute("fumigation-record/:id?", <FumigationRecord />),
      createRoute("appron-and-slipper-cleaning-record/:id?", <ApproneAndSlipperCleaningRecord />),
      createRoute("temperature-and-humidity-monitoring-record/:id?", <TemperatureAndHumidityMonitoringRecord />),
      createRoute("cleaning-register/:id?", <CleaningRegister />),
      createRoute("personnel-hygiene-record/:id?", <PersonnelHygiene />),
    ]),
  ]
);

export default WERoutes;
