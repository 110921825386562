import { commonRoutesConstants } from "services/keyConstants";

// const baseUrl = "/work-environment";
const baseUrl = "/work-environment";
const baseUrlV = "/work-environment-new";

export const CLICK_STERILE_DOCUMENT = `${baseUrl}/`;
export const YEAR_CREATE = `${baseUrlV}/`;
export const GET_VERSIONS = `${baseUrlV}/get-versions`;
export const GET_ALL_DATA = `${baseUrlV}/get-list`;
export const UPDATE_SPECIFIC_DATA = `${baseUrlV}/update-specific`;
// export const GET_BY_ID = `${baseUrl}/`;
export const GET_DATA_BY_METAINFO = `${baseUrlV}/get-document-by-month`;
export const DOWNLOAD_WE = `${baseUrlV}/download`;
export const DOWNLOAD_EXCEL = `${baseUrlV}/download-excel`;
export const GET_STATUS_DATA = `${baseUrlV}/get-dynamic`;
export const GET_LIST_FOR_CLEANING_REGISTER = `${baseUrlV}/get-by-meta-info`;
export const GET_DISINFECT_TABLE_PROCEDURE = `${baseUrlV}/get-procedure-table`;
export const GET_BY_PROCEDURE_ID = "/procedure/";


export const GET_ALL_FORMATS = `${baseUrlV}/get-master-docs`
export const GET_YEAR_AND_MONTH_FORMATS = `${baseUrlV}/month-list`
export const GET_BY_YEAR = `${baseUrlV}/${commonRoutesConstants.YEAR_LIST}`;
export const CREATE = `${baseUrlV}/create`;
export const GET_BY_ID = `${baseUrlV}/`;